import { useEffect, useState } from "react";
import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";

import LandingPageSettings from "../../service/LandingPageSettings";


function TentangView(){
    const [ textAbout, setTextAbout ] = useState('')
    useEffect(() => {
        const getDataBanner = async ( ) => {
            try {
                const response = await LandingPageSettings()
                setTextAbout(response.data.data[3].content);
            } catch (e) {
                console.error('error : ', e);
            }
        }    
        getDataBanner()
    }, [])
    return(
        <div>
            <NavbarComponent />
            <div className="md:my-[5rem] my-10 container mx-auto md:px-[10rem] px-[1rem] min-h-screen">
                <div className="border-gray-300 border-2 rounded-lg shadow-xl md:px-[7rem] px-[2rem] md:py-[5rem] py-[2rem]">
                    <h1 className="text-center lg:text-[2rem] md:text-[1.5rem] text-[1.2rem] font-bold">TENTANG SI ABAH JAWARA</h1><br />
                    <div dangerouslySetInnerHTML={{ __html: textAbout }}></div>
                </div>
            </div>
            <CopyrightComponent />            
        </div>
    )
}
export default TentangView;