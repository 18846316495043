import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import ProfileComponent from "../../components/profile-component/ProfileComponent";

export default function ProfileView(){
    return(
        <>
            <NavbarComponent />
            <ProfileComponent />
            <CopyrightComponent />
        </>
    )
}