import axios from "axios";
import Cookies from "js-cookie";

const RiwayatProposal = async(value) => {
    const currentUrl = new URL(window.location.href);
    const search = currentUrl.searchParams.get('search');
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.get(`${api}/proposal?status=${value.status}&paginate=5&search=${
            value.search === '' ? (search ? search : '') : (value.search)
        }&page=${value.page}`, {
            headers: {
                Authorization: `Bearer ${bearer}`
            }
        });
        return req;
    } catch (error) {
        return error.response;
    }
}

const DetailProposal = async(slug) => {
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.get(`${api}/proposal/${slug}`, {
            headers: {
                Authorization: `Bearer ${bearer}`
            }
        });
        return req;
    } catch (error) {
        return error.response;
    }
}

const ListProposal = async(value) => {
    const currentUrl = new URL(window.location.href);
    const search = currentUrl.searchParams.get('search');
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.get(`${api}/proposal-list?status=${value.status}&search=${
            value.search === '' ? (search ? search : '') : (value.search)
        }&paginate=10&page=${value.page}`);
        return req;
        
    } catch (error) {
        return error.response;
    }
}

const ListDetailProposal = async(slug) => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.get(`${api}/proposal-list/${slug}`);
        return req;
    } catch (error) {
        return error.response;
    }
}

const Proposal = (query, data) => {
    if( query === 'riwayat' ) return RiwayatProposal(data)
    else if ( query === 'detail' ) return DetailProposal(data)
    else if ( query === 'list' ) return ListProposal(data)
    else if ( query === 'list-detail' ) return ListDetailProposal(data)
}
export default Proposal;

