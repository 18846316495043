import React, { useState, useEffect, useRef } from 'react';
import { faCheck, faClose, faRepeat } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chaptca1 from '../../assets/images/captcha1.jpg';
import Chaptca2 from '../../assets/images/captcha2.jpg';
import Chaptca3 from '../../assets/images/captcha3.jpg';
import LoaderComponent from '../LoaderComponent';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import Auth from '../../service/Auth';
import Swal from 'sweetalert2';

const RegisterForm = () => {
    const [ loading, setLoading ] = useState(false);
    const [ flashData, setFlashData ] = useState(false);

    const [captcha, setCaptcha] = useState('');
    const canvasRef = useRef(null);

    useEffect(() => {
        const newCaptcha = generateCaptcha();
        setCaptcha(newCaptcha);
        drawCaptcha(newCaptcha);
    }, []);

    const drawCaptcha = (text) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const drawRandomBackgroundImage = () => {
            const images = [Chaptca1, Chaptca2, Chaptca3];
            const randomImage = images[Math.floor(Math.random() * images.length)];
            const img = new Image();
            img.src = randomImage;
            img.onload = () => {
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                drawTextOnImage(text);
            };
        };
        const drawTextOnImage = (text) => {
            const characters = text.split('');
            const angleVariation = [45, -30, 0, 30, 45];
            characters.forEach((char, index) => {
                const xPos = 28 + index * 30;
                const yPos = 27.5;
                const randomRotation = angleVariation[Math.floor(Math.random() * angleVariation.length)];
                ctx.save();
                ctx.translate(xPos, yPos);
                ctx.rotate((randomRotation * Math.PI) / 180);
                ctx.font = '22px Arial';
                ctx.lineWidth = 2;
                ctx.fillStyle = '#017C2B';
                ctx.strokeStyle = '#017C2B'
                ctx.fillText(char, -8, 0);
                ctx.strokeText(char, -8, 0);
                ctx.restore();
            });
        };
        drawRandomBackgroundImage();
    };

    const regenerateCaptcha = () => {
        const newCaptcha = generateCaptcha();
        setCaptcha(newCaptcha);
        drawCaptcha(newCaptcha);
    };

    const [ registerForm, setRegisterForm ] = useState({
        username: '',
        password: '',
        passwordConfirmation: '',
        name: '',
        address: '',
        phoneNumber: '',
        nik: '',
        email: '',
        jenis_kelamin: '',
        captcha: ''
    })

    const [ registerFormError, setRegisterFormError ] = useState({
        username: '',
        password: '',
        passwordConfirmation: '',
        name: '',
        address: '',
        phoneNumber: '',
        nik: '',
        email: '',
        jenis_kelamin: '',
        captcha: ''
    })

    const __rules = () => {
        const errors = {};
        if ( !registerForm.username ) { errors.username = 'Nama pengguna tidak boleh kosong'; }
        if ( !registerForm.password ) { 
            errors.password = 'Kata sandi tidak boleh kosong';
        } else if (registerForm.password.length < 8) {
            errors.password = 'Kata sandi minimal 8 karakter';
        }
        if ( !registerForm.passwordConfirmation ) { 
            errors.passwordConfirmation = 'Konfirmasi kata sandi tidak boleh kosong';
        } else if ( registerForm.password !== registerForm.passwordConfirmation ){ 
            errors.passwordConfirmation = 'Konfirmasi kata sandi tidak sama'; 
        }
        if ( !registerForm.name ) { errors.name = 'Nama lengkap tidak boleh kosong'; }
        if ( !registerForm.address ) { errors.address = 'Alamat tidak boleh kosong'; }
        if ( !registerForm.phoneNumber ) { errors.phoneNumber = 'No. Hp tidak boleh kosong'; }
        if ( !registerForm.jenis_kelamin ) { errors.jenis_kelamin = 'Jenis kelamin tidak boleh kosong'; }
        if ( !registerForm.nik ) { 
            errors.nik = 'NIK tidak boleh kosong'; 
        } else if ( !/^\d{16}$/.test(registerForm.nik) ) {
            errors.nik = 'NIK harus berisikan 16 angka'; 
        }
        if (!registerForm.email) {
            errors.email = 'Email tidak boleh kosong';
        } else if (!/\S+@\S+\.\S+/.test(registerForm.email)) {
            errors.email = 'Format email tidak valid';
        }
        if ( !registerForm.captcha ) { 
            errors.captcha = 'Captcha tidak boleh kosong'; regenerateCaptcha(); 
        } else if ( registerForm.captcha !== captcha ) { 
            errors.captcha = 'Captcha tidak sesuai'; regenerateCaptcha(); 
        }
        return errors;
    }

    const handleRegistration = async ( e ) => {
        e.preventDefault();
        setFlashData(false)
        setLoading(true);
        setRegisterFormError({
            username: '',
            password: '',
            passwordConfirmation: '',
            name: '',
            address: '',
            phoneNumber: '',
            nik: '',
            email: '',
            jenis_kelamin: '',
            captcha: ''
        })
        const errors = __rules();
        if (Object.keys(errors).length === 0) {
            const dataSend = {
                username: registerForm.username,
                kata_sandi: registerForm.password,
                kata_sandi_confirmation: registerForm.passwordConfirmation,
                nama_lengkap: registerForm.name,
                alamat: registerForm.address,
                nomor_hp: registerForm.phoneNumber,
                nik: registerForm.nik,
                jenis_kelamin: registerForm.jenis_kelamin,
                email: registerForm.email,
            }

            try {
                const response = await Auth('register', dataSend);
                if(response.status === 200){
                    if(response.data.error){
                        Swal.fire({
                            html: response.data.error[0],
                            icon: "error"
                        })
                    } else {
                        Swal.fire({
                            html: response.data.message,
                            icon: "success"
                        }).then(() => {
                            document.location.href = '/';
                        })
                    }
                }
                console.log(response)
                setLoading(false);
            } catch (e) { console.error('error : ', e); }
        } else {
            setRegisterFormError(errors);
            setLoading(false);
        }
    };

    const [ openPassword, setOpenPassword ] = useState(false)
    const [ openConfirmPassword, setOpenConfirmPassword ] = useState(false)

    return(
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:my-[5rem] my-4 container mx-auto md:px-[5rem] px-[1rem]">
                { flashData && (
                    <div className="success-flashdata mb-3 md:flex items-center md:text-[1rem] text-[.75rem]">
                        <FontAwesomeIcon icon={faCheck} className="text-primary md:me-5 me-3" />
                        <strong className="me-2">Pendaftaran Berhasil!</strong>
                        <strong className="font-semibold text-gray-500">Silahkan login menggunakan akun yang sudah didaftarkan untuk bisa melakukan Pengajuan Hibah dan Bansos</strong>
                        <FontAwesomeIcon onClick={() => setFlashData(false)} icon={faClose} className="md:block hidden ms-auto cursor-pointer" />
                    </div>
                ) }
                <div className="border-gray-300 border-2 rounded-lg shadow-xl">
                    <div className="text-center py-4 md:px-10 px-4">
                        <h1 className="font-semibold md:text-[2rem] mb-3 text-[1.2rem]">Daftar</h1>
                        <div className="mb-3 md:text-[1rem] text-[.6rem]">
                            <p>Daftarkan diri anda atau organisasi anda untuk dapat melakukan <br className='md:block hidden' />pengajuan proposal Bantuan Sosial dan Bansos Online</p>
                        </div>
                        <br />
                        <div>
                            <form action="" method="post" onSubmit={handleRegistration}>
                                <div className="mb-3 text-start">
                                    <input className={`italic border rounded-lg w-full py-2 px-3 ${registerFormError.username ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} id="username" value={registerForm.username} onChange={ (e) => setRegisterForm({...registerForm, username: e.target.value}) } type="text" placeholder="Nama Pengguna" />
                                    {registerFormError.username !== '' && (
                                        <small className="text-red-500"><em>{registerFormError.username}</em></small>
                                    )}
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-3">
                                    <div className="mb-3 text-start relative">
                                        <input 
                                            className={`italic border rounded-lg w-full py-2 ps-3 pe-9 ${registerFormError.password ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                            id="password" 
                                            value={registerForm.password} 
                                            onChange={ (e) => setRegisterForm({...registerForm, password: e.target.value}) } 
                                            type={openPassword ? 'text' : 'password'}
                                            placeholder="Atur Kata Sandi" />
                                        <FontAwesomeIcon icon={!openPassword ? faEye : faEyeSlash} onClick={() => setOpenPassword(!openPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                        {registerFormError.password !== '' && (
                                            <small className="text-red-500"><em>{registerFormError.password}</em></small>
                                        )}
                                    </div>
                                    <div className="mb-3 text-start relative">
                                        <input 
                                            className={`italic border rounded-lg w-full py-2 ps-3 pe-9 ${registerFormError.passwordConfirmation ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                            id="password-confirmation" 
                                            value={registerForm.passwordConfirmation} 
                                            onChange={ (e) => setRegisterForm({...registerForm, passwordConfirmation: e.target.value}) } 
                                            type={openConfirmPassword ? 'text' : 'password'}
                                            placeholder="Konfirmasi Kata Sandi" />
                                        <FontAwesomeIcon icon={!openConfirmPassword ? faEye : faEyeSlash} onClick={() => setOpenConfirmPassword(!openConfirmPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                        {registerFormError.passwordConfirmation !== '' && (
                                            <small className="text-red-500"><em>{registerFormError.passwordConfirmation}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 text-start">
                                    <input className={`italic border rounded-lg w-full py-2 px-3 ${registerFormError.name ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={registerForm.name} onChange={ (e) => setRegisterForm({...registerForm, name: e.target.value}) } id="name" type="text" placeholder="Nama Lengkap (Individu atau Lembaga)" />
                                    {registerFormError.name !== '' && (
                                        <small className="text-red-500"><em>{registerFormError.name}</em></small>
                                    )}
                                </div>
                                <div className="mb-3 text-start">
                                    <textarea className={`italic border rounded-lg w-full py-2 px-3 mb-[-5px] ${registerFormError.address ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={registerForm.address} onChange={ (e) => setRegisterForm({...registerForm, address: e.target.value}) } id="address" rows="4" placeholder="Alamat Lengkap" ></textarea>
                                    {registerFormError.address !== '' && (
                                        <small className="text-red-500"><em>{registerFormError.address}</em></small>
                                    )}
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-3">
                                <div className="mb-3 text-start">
                                        <select 
                                            className={`italic border rounded-lg w-full py-2 px-3 ${registerFormError.jenis_kelamin ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                            value={registerForm.jenis_kelamin} 
                                            onChange={ (e) => setRegisterForm({...registerForm, jenis_kelamin: e.target.value}) } 
                                            id="jenis_kelamin">
                                            <option value="" disabled>-- Pilih Jenis Kelamin --</option>
                                            <option value="Laki - Laki">Laki - Laki</option>
                                            <option value="Perempuan">Perempuan</option>
                                        </select>
                                        {registerFormError.nik !== '' && (
                                            <small className="text-red-500"><em>{registerFormError.jenis_kelamin}</em></small>
                                        )}
                                    </div>
                                    <div className="mb-3 text-start">
                                        <input className={`italic border rounded-lg w-full py-2 px-3 ${registerFormError.phoneNumber ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={registerForm.phoneNumber} onChange={ (e) => setRegisterForm({...registerForm, phoneNumber: e.target.value}) } id="phone-number" type="number" placeholder="No. Hp Pendaftar" />
                                        {registerFormError.phoneNumber !== '' && (
                                            <small className="text-red-500"><em>{registerFormError.phoneNumber}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-3">
                                    <div className="mb-3 text-start">
                                        <input className={`italic border rounded-lg w-full py-2 px-3 ${registerFormError.nik ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={registerForm.nik} onChange={ (e) => setRegisterForm({...registerForm, nik: e.target.value}) } id="nik" type="number" placeholder="NIK Pendaftar" />
                                        {registerFormError.nik !== '' && (
                                            <small className="text-red-500"><em>{registerFormError.nik}</em></small>
                                        )}
                                    </div>
                                    <div className="mb-3 text-start">
                                        <input className={`italic border rounded-lg w-full py-2 px-3 ${registerFormError.email ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} id="email" type="text" placeholder="Email aktif pendaftar"  value={registerForm.email} onChange={ (e) => setRegisterForm({...registerForm, email: e.target.value}) } />
                                        {registerFormError.email !== '' && (
                                            <small className="text-red-500"><em>{registerFormError.email}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className='md:flex justify-center align-center'>
                                        <canvas ref={canvasRef} width={150} height={40} className='rounded border border-gray-400 md:ms-0 ms-[50%] md:translate-x-0 translate-x-[-50%] md:mb-0 mb-3'></canvas>
                                        <input className={`italic border rounded-lg md:w-[20%] ms-3 py-2 px-3 ${registerFormError.captcha ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={registerForm.captcha} onChange={(e) => setRegisterForm({ ...registerForm, captcha: e.target.value })} placeholder="Enter CAPTCHA" />
                                        <button onClick={regenerateCaptcha} type='button' className='md:p-3 p-3 border rounded-lg bg-primary text-white ms-2 md:mt-0 mt-2'>
                                            <FontAwesomeIcon icon={faRepeat} />
                                        </button><br />
                                    </div>
                                    {registerFormError.captcha !== '' && (
                                        <small className="text-red-500"><em>{registerFormError.captcha}</em></small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <button className="w-full bg-primary text-white p-2 rounded-lg">Daftar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RegisterForm;

const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let captcha = '';
    for (let i = 0; i < 4; i++) {
        captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
};