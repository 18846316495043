/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import SearchProposal from "./SearchProposal";
import ProcessingProposal from "./ProcessingProposal";
import SuccessProposal from "./SuccessProposal";
import ErrorProposal from "./ErrorProposal";
import Proposal from "../../service/Proposal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import LoaderComponent from "../LoaderComponent";

const ProposalCover = () => {
    const [ loading, setLoading ] = useState(false)
    const currentUrl = new URL(window.location.href);
    const [ searchParam, setSearchParam ] = useState('');
    
    const [ menu, setMenu ] = useState({
        diproses: true,
        disetujui: false,
        ditolak: false
    })

    const changePage = ( parameter ) => {
        setMenu({
            diproses: parameter === 'diproses',
            disetujui: parameter === 'disetujui',
            ditolak: parameter === 'ditolak',
        })
    }

    const navMenu = [
        {className: menu.diproses ? 'bg-primary text-white' : 'text-primary', click: 'diproses', title: 'Sedang Diproses'},
        {className: menu.disetujui ? 'bg-primary text-white' : 'text-primary', click: 'disetujui', title: 'Disetujui'},
        {className: menu.ditolak ? 'bg-primary text-white' : 'text-primary', click: 'ditolak', title: 'Ditolak'}
    ]
    
    useEffect(() => {
        setSearchParam(currentUrl.searchParams.get('search'));
    }, [currentUrl])

    const [ propData, setPropData ] = useState({});
    const [ page, setPage ] = useState(1);

    const riwayatCheck = async(menu, search, page) => {
        setLoading(true)
        try{
            const dataProposal = {
                status: menu.diproses ? 'diproses' : menu.disetujui ? 'disetujui' : menu.ditolak ? 'ditolak' : 'all',
                search: search ? search : '',
                page: page
            }
            const response = await Proposal('riwayat', dataProposal)
            setPropData(response.data.data)
            setLoading(false)
        } catch (e) { console.error('error : ', e); setLoading(false) }
    }

    useEffect(() => {
        riwayatCheck(menu, searchParam, 1);
    },[menu, searchParam])
    return(
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:my-[5rem] my-4 container mx-auto lg:px-[10rem] px-[1rem]">
                <SearchProposal />
                <div className="flex my-3">
                    { navMenu.map((item, index) => (
                        <button className={`bg-white border rounded-lg p-2 border-primary me-2 my-2 ${item.className}`} onClick={() => changePage(item.click)} key={index}>
                            {item.title}
                        </button>
                    )) }
                </div>
                { menu.diproses && <ProcessingProposal data={propData} /> }
                { menu.disetujui && <SuccessProposal data={propData} /> }
                { menu.ditolak && <ErrorProposal data={propData} /> }
                {propData.total > 0 && (
                    <div className="flex items-center flex-wrap py-3">
                        <button 
                            onClick={() => {
                                riwayatCheck(menu, searchParam, 1)
                                setPage(1)
                            }}
                            className="bg-primary px-3 py-1 text-white rounded-lg mx-1 mb-1">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </button>
                        <button 
                            onClick={() => {
                                riwayatCheck(menu, searchParam, page-1)
                                setPage(page-1)
                            }}
                            disabled={page === 1}
                            className="bg-primary px-3 py-1 text-white rounded-lg mx-1 mb-1">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        {Array.from({ length: propData.last_page }, (_, index) => (
                            <button 
                                key={index + 1} 
                                onClick={() => {
                                    riwayatCheck(menu, searchParam, index+1)
                                    setPage(index+1)
                                }}
                                className={`${index + 1 === page ?  'bg-white text-primary border border-primary': 'bg-primary'} px-3 py-1 text-white rounded-lg mx-1 mb-1`}>
                            
                                {index + 1}

                            </button>
                        ))}
                        <button 
                            onClick={() => {
                                riwayatCheck(menu, searchParam, page+1)
                                setPage(page+1)
                            }}
                            disabled={page === propData.last_page}
                            className="bg-primary px-3 py-1 text-white rounded-lg mx-1 mb-1">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                        <button 
                            onClick={() => {
                                riwayatCheck(menu, searchParam, propData.last_page)
                                setPage(propData.last_page)
                            }}
                            className="bg-primary px-3 py-1 text-white rounded-lg mx-1 mb-1">
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}
export default ProposalCover;