import { faCheckCircle, faPlusCircle, faTrash, faUpload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ProposalStore from "../../service/ProposaStore";
import JenisAnggaran from "../../service/JenisAnggaran";
import LoaderComponent from "../LoaderComponent";
import Swal from "sweetalert2";
import Select from "react-select";

const HibahForm = () => {
    const [ loading, setLoading ] = useState(false)
    // Jenis ke sub kategori
        const [ NPSN, setNPSN ] = useState('');
        const [ hibahChecked, setHibahChecked ] = useState(true);
        const [ bansosChecked, setBansosChecked ] = useState(false);
        const [ subCategory, setSubCategory ] = useState(null);
        const dataSubKategori = async ( kategori ) => {
            try {
                const res = await ProposalStore('sub-kategori', kategori)
                setSubCategory(res.data.data)
                setNPSN('')
            } catch(e) { console.error('error : ', e); }
            setLoading(false)
        }
        useEffect(() => {
            if(hibahChecked) dataSubKategori('hibah')
            else if(bansosChecked) dataSubKategori('bansos')
        }, [hibahChecked, bansosChecked])


    // Sub kategori ke requirement input
        const [ subCategoryValue, setSubCategoryValue ] = useState("")
        const [ requiredInputs, setRequiredInputs ] = useState([]);
        const [ requirementAttachement, setRequirementAttachement ] = useState([]);
        const [ attachmentData, setAttachmentData ] = useState({});

        const changeSubCategory = (e) => {
            setAttachmentData({});
            const value = JSON.parse(e.target.value)
            if(value.inputs.length > 0) setRequiredInputs(value.inputs)
            else {
                setNPSN('')
                setRequiredInputs([])
            }

            if(value.dokumen_wajib.length > 0) setRequirementAttachement(value.dokumen_wajib)
            else {
                setRequirementAttachement([])
            }

            setSubCategoryValue(e.target.value)
        }
        const handleChangeImage = ( e, attachmentFile, index ) => {
            const file = e.target.files[0];
            if(file){
                const reader = new FileReader();
                reader.onloadend = () => {
                    setAttachmentData({
                        ...attachmentData,
                        [attachmentFile]: { name: file.name, file: e.target.files[0] }
                    })
                };
                reader.readAsDataURL(file);
            }
        }

    // Jenis anggaran
        const [ jenisAnggaran, setJenisAnggaran ] = useState([])
        useEffect(() => {
            const getJenisAnggaran = async () => {
                try {
                    const response = await JenisAnggaran();
                    setJenisAnggaran(response.data.data);
                } catch(e) {console.error('error : ', e)}
            }

            getJenisAnggaran()
        }, [])

    // Image Data
        const [ imageData, setImageData ] = useState([{ name: '', file: null }]);
        const addImage = () => {
            setImageData((prevData) => [...prevData, { name: '', file: null }]);
        };
        const removeImage = (index) => {
            setImageData((prevData) => {
                const newImageData = [...prevData];
                newImageData.splice(index, 1);
                return newImageData;
            });
        };
        const handleChangeImageData = ( e, index ) => {
            const file = e.target.files[0];
            if(file){
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageData((prevData) => {
                        const newOtherAttachments = [...prevData];
                        newOtherAttachments[index] = { name: file.name, file: e.target.files[0] };
                        return newOtherAttachments;
                    });
                };
                reader.readAsDataURL(file);
            }
        }

    // Proposal Pengajuan
        const [ proposalPengajuan, setProposalPengajuan ] = useState({ name: '', file: null });
        const handleChangeProposal = ( e ) => {
            const file = e.target.files[0];
            if(file){
                const reader = new FileReader();
                reader.onloadend = () => {
                    setProposalPengajuan({ name: file.name, file: e.target.files[0] })
                };
                reader.readAsDataURL(file);
            }
        }

    // Dokumen Pendukung
        const [ dokumenPendukung, setDokumenPendukung ] = useState([{ name: '', file: null }]);
        const addDokumen = () => {
            setDokumenPendukung((prevData) => [...prevData, { name: '', file: null }]);
        };
        const removeDokumen = (index) => {
            setDokumenPendukung((prevData) => {
                const newDokumenData = [...prevData];
                newDokumenData.splice(index, 1);
                return newDokumenData;
            });
        };
        const handleChangeDokumenPendukung = ( e, index ) => {
            const file = e.target.files[0];
            if(file){
                const reader = new FileReader();
                reader.onloadend = () => {
                    setDokumenPendukung((prevData) => {
                        const newOtherAttachments = [...prevData];
                        newOtherAttachments[index] = { name: file.name, file: e.target.files[0] };
                        return newOtherAttachments;
                    });
                };
                reader.readAsDataURL(file);
            }
        }
  
    // Goals
        const [goalList, setGoalList] = useState(['']);
        const addGoals = () => {
            const newgoalList = [...goalList, ''];
            setGoalList(newgoalList);
        };
        const removeGoals = (index) => {
            const newgoalList = [...goalList];
            newgoalList.splice(index, 1);
            setGoalList(newgoalList);
        };

    // Form and Error Data
        const [ formData, setFormData ] = useState({
            nama: '',
            address: '',
            desa: '',
            kecamatan: '',
            title: '',
            background: '',
            total_fund: '',
            jenis_anggaran: '',
            desc: '',
        }); 
        const [ errorData, setErrorData ] = useState({
            sub_kategori: '',
            nama: '',
            address: '',
            desa: '',
            kecamatan: '',
            title: '',
            background: '',
            total_fund: '',
            jenis_anggaran: '',
            desc: '',
        })

        // Currency on total fund
        const [errorMessage, setErrorMessage] = useState('');

        const handleChange = (e) => {
            const { value } = e.target;
            // Hanya izinkan angka
            const numericValue = value.replace(/\D/g, '');
            setFormData({ ...formData, total_fund: numericValue });
    
            // Validasi input
            if (parseInt(numericValue) < 99999) {
                setErrorMessage("Masukan nominal yang sesuai");
            } else {
                setErrorMessage("");
            }
        };


        const [desaOptions, setDesaOptions] = useState([]);
        const [kecamatanOptions] = useState([]);
        const kecamatanData = [
            { value: "Sagalaherang",    label: "Sagalaherang" },
            { value: "Subang",          label: "Subang" },
            { value: "Pamanukan",       label: "Pamanukan" },
            { value: "Cisalak",         label: "Cisalak" },
            { value: "Kalijati",        label: "Kalijati" },
            { value: "Pabuaran",        label: "Pabuaran" },
            { value: "Purwadadi",       label: "Purwadadi" },
            { value: "Pagaden",         label: "Pagaden" },
            { value: "Binong",          label: "Binong" },
            { value: "Ciasem",          label: "Ciasem" },
            { value: "Pusakanagara",    label: "Pusakanagara" },
            { value: "Jalancagak",      label: "Jalancagak" },
            { value: "Blanakan",        label: "Blanakan" },
            { value: "Tanjungsiang",    label: "Tanjungsiang" },
            { value: "Compreng",        label: "Compreng" },
            { value: "Patokbeusi",      label: "Patokbeusi" },
            { value: "Cibogo",          label: "Cibogo" },
            { value: "Cipunagara",      label: "Cipunagara" },
            { value: "Cijambe",         label: "Cijambe" },
            { value: "Cipeundeuy",      label: "Cipeundeuy" },
            { value: "Legonkulon",      label: "Legonkulon" },
            { value: "Serangpanjang",   label: "Serangpanjang" },
            { value: "Sukasari",        label: "Sukasari" },
            { value: "Tambakdahan",     label: "Tambakdahan" },
            { value: "Kasomalang",      label: "Kasomalang" },
            { value: "Dawuan",          label: "Dawuan" },
            { value: "Pagaden Barat",   label: "Pagaden Barat" },
            { value: "Ciater",          label: "Ciater" },
            { value: "Pusakajaya",      label: "Pusakajaya" },
            { value: "Sukasari",        label: "Sukasari" },
            { value: "Cikaum",          label: "Cikaum" },
        ];
        const desaData = {
            "Sagalaherang" : [
                { value: "Limpas",              label: "Limpas" },                { value: "Mekarsari",           label: "Mekarsari" },
                { value: "Patrol Baru",         label: "Patrol Baru" },           { value: "Patrol Lor",          label: "Patrol Lor" },
                { value: "Patrol",              label: "Patrol" },                { value: "Bugel",               label: "Bugel" },
                { value: "Arjasari",            label: "Arjasari" },              { value: "Sukahaji",            label: "Sukahaji" },
                { value: "Sukamandi",           label: "Sukamandi" },             { value: "Cicadas",             label: "Cicadas" },
                { value: "Sagalaherang Kidul",  label: "Sagalaherang Kidul" },    { value: "Sagalaherang Kaler",  label: "Sagalaherang Kaler" },
                { value: "Curugagung",          label: "Curugagung" },            { value: "Leles",               label: "Leles" },
                { value: "Dayeuh Kolot",        label: "Dayeuh Kolot" },
            ],
            "Subang" : [
                { value: "Parung",              label: "Parung" },                { value: "Cigadung",            label: "Cigadung" },
                { value: "Pasirkareumbi",       label: "Pasirkareumbi" },         { value: "Karanganyar",         label: "Karanganyar" },
                { value: "Soklat",              label: "Soklat" },                { value: "Dangdeur",            label: "Dangdeur" },
                { value: "Sukamelang",          label: "Sukamelang" },            { value: "Wanareja",            label: "Wanareja" }
            ],
            "Pamanukan" : [
                { value: "Rancasari",           label: "Rancasari" },             { value: "Rancahilir",          label: "Rancahilir" },
                { value: "Pamanukan",           label: "Pamanukan" },             { value: "Pamanukan Hilir",     label: "Pamanukan Hilir" },
                { value: "Pamanukan Sebrang",   label: "Pamanukan Sebrang" },     { value: "Mulyasari",           label: "Mulyasari" },
                { value: "Lengkongjaya",        label: "Lengkongjaya" },          { value: "Bongas",              label: "Bongas" }
            ],
            "Cisalak" : [
                { value: "Cupunagara",          label: "Cupunagara" },            { value: "Cimanggu",            label: "Cimanggu" },
                { value: "Gardusayang",         label: "Gardusayang" },           { value: "Mayang",              label: "Mayang" },
                { value: "Sukakerti",           label: "Sukakerti" },             { value: "Darmaga",             label: "Darmaga" },
                { value: "Cisalak",             label: "Cisalak" },               { value: "Cigadog",             label: "Cigadog" },
                { value: "Pakuhaji",            label: "Pakuhaji" }
            ],
            "Kalijati" : [
                { value: "Marengmang",          label: "Marengmang" },            { value: "Kalijati Timur",      label: "Kalijati Timur" },
                { value: "Kaliangsana",         label: "Kaliangsana" },           { value: "Kalijati Barat",      label: "Kalijati Barat" },
                { value: "Tanggulun Timur",     label: "Tanggulun Timur" },       { value: "Tanggulun Barat",     label: "Tanggulun Barat" },
                { value: "Caracas",             label: "Caracas" },               { value: "Ciruluk",             label: "Ciruluk" },
                { value: "Jalupang",            label: "Jalupang" },              { value: "Banggalamulya",       label: "Banggalamulya" }
            ],
            "Pabuaran" : [
                { value: "Cihambulu",           label: "Cihambulu" },             { value: "Siluman",             label: "Siluman" },
                { value: "Pringkasap",          label: "Pringkasap" },            { value: "Pabuaran",            label: "Pabuaran" },
                { value: "Karanghegar",         label: "Karanghegar" },           { value: "Salamjaya",           label: "Salamjaya" },
                { value: "Kadawung",            label: "Kadawung" },              { value: "Balebandung Jaya",    label: "Balebandung Jaya" },
            ], 
            "Purwadadi" : [
                { value: "Wanakerta",           label: "Wanakerta" },
                { value: "Pagon",               label: "Pagon" },
                { value: "Prapatan",            label: "Prapatan" },
                { value: "Purwadadi",           label: "Purwadadi" },
                { value: "Belendung",           label: "Belendung" },
                { value: "Koranji",             label: "Koranji" },
                { value: "Panyingkiran",        label: "Panyingkiran" },
                { value: "Pasirbungur",         label: "Pasirbungur" },
                { value: "Purwadadi Barat",     label: "Purwadadi Barat" },
                { value: "Rancamahi",           label: "Rancamahi" }
            ],
            "Pagaden" : [
                { value: "Gunungsembung",       label: "Gunungsembung" },
                { value: "Gembor",              label: "Gembor" },
                { value: "Kamarung",            label: "Kamarung" },
                { value: "Pagaden",             label: "Pagaden" },
                { value: "Gambarsari",          label: "Gambarsari" },
                { value: "Neglasari",           label: "Neglasari" },
                { value: "Sukamulya",           label: "Sukamulya" },
                { value: "Sumbersari",          label: "Sumbersari" },
                { value: "Gunungsari",          label: "Gunungsari" },
                { value: "Jabong",              label: "Jabong" }
            ], 
            "Binong" : [
                { value: "Cicadas",             label: "Cicadas" },
                { value: "Kihiyang",            label: "Kihiyang" },
                { value: "Binong",              label: "Binong" },
                { value: "Kediri",              label: "Kediri" },
                { value: "Citrajaya",           label: "Citrajaya" },
                { value: "Mulyasari",           label: "Mulyasari" },
                { value: "Karangsari",          label: "Karangsari" },
                { value: "Karangwangi",         label: "Karangwangi" },
                { value: "Nanggerang",          label: "Nanggerang" }
            ],
            "Ciasem" : [
                { value: "Ciasem Girang",       label: "Ciasem Girang" },
                { value: "Ciasem Tengah",       label: "Ciasem Tengah" },
                { value: "Ciasem Hilir",        label: "Ciasem Hilir" },
                { value: "Ciasem Baru",         label: "Ciasem Baru" },
                { value: "Dukuh",               label: "Dukuh" },
                { value: "Pinangsari",          label: "Pinangsari" },
                { value: "Jatibaru",            label: "Jatibaru" },
                { value: "Sukamandijaya",       label: "Sukamandijaya" },
                { value: "Sukahaji",            label: "Sukahaji" },
            ], 
            "Pusakanagara" : [
                { value: "Pusakaratu",         label: "Pusakaratu" },
                { value: "Gempol",             label: "Gempol" },
                { value: "Kalentambo",         label: "Kalentambo" },
                { value: "Patimban",           label: "Patimban" },
                { value: "Rancadaka",          label: "Rancadaka" },
                { value: "Kotasari",           label: "Kotasari" },
                { value: "Mundusari",          label: "Mundusari" }
            ], 
            "Jalancagak" : [
                { value: "Curugrendeng",       label: "Curugrendeng" },
                { value: "Tambakan",           label: "Tambakan" },
                { value: "Bunihayu",           label: "Bunihayu" },
                { value: "Kumpay",             label: "Kumpay" },
                { value: "Sarireja",           label: "Sarireja" },
                { value: "Tambakmekar",        label: "Tambakmekar" },
                { value: "Jalancagak",         label: "Jalancagak" }
            ], 
            "Blanakan" : [
                { value: "Tanjungtiga",        label: "Tanjungtiga" },
                { value: "Muara",              label: "Muara" },
                { value: "Blanakan",           label: "Blanakan" },
                { value: "Rawameneng",         label: "Rawameneng" },
                { value: "Cilamayagirang",     label: "Cilamayagirang" },
                { value: "Jayamukti",          label: "Jayamukti" },
                { value: "Cilamayahilir",      label: "Cilamayahilir" },
                { value: "Rawamekar",          label: "Rawamekar" },
                { value: "Langensari",         label: "Langensari" }
            ],
            "Tanjungsiang" : [
                { value: "Buniara",            label: "Buniara" },
                { value: "Tanjungsiang",       label: "Tanjungsiang" },
                { value: "Cimeuhmal",          label: "Cimeuhmal" },
                { value: "Sirap",              label: "Sirap" },
                { value: "Kawungluwuk",        label: "Kawungluwuk" },
                { value: "Cibuluh",            label: "Cibuluh" },
                { value: "Sindanglaya",        label: "Sindanglaya" },
                { value: "Rancamanggung",      label: "Rancamanggung" },
                { value: "Cikawung",           label: "Cikawung" },
                { value: "Gandasoli",          label: "Gandasoli" }
            ], 
            "Compreng" : [
                { value: "Jatimulya",          label: "Jatimulya" },
                { value: "Sukatani",           label: "Sukatani" },
                { value: "Jatireja",           label: "Jatireja" },
                { value: "Compreng",           label: "Compreng" },
                { value: "Kalensari",          label: "Kalensari" },
                { value: "Mekarjaya",          label: "Mekarjaya" },
                { value: "Kiarasari",          label: "Kiarasari" },
                { value: "Sukadana",           label: "Sukadana" }
            ], 
            "Patokbeusi" : [
                { value: "Rancabango",          label: "Rancabango" },
                { value: "Tanjungrasa",         label: "Tanjungrasa" },
                { value: "Ciberes",             label: "Ciberes" },
                { value: "Jatiragas Hilir",     label: "Jatiragas Hilir" },
                { value: "Tambakjati",          label: "Tambakjati" },
                { value: "Rancajaya",           label: "Rancajaya" },
                { value: "Gempolsari",          label: "Gempolsari" },
                { value: "Tanjungrasa Kidul",   label: "Tanjungrasa Kidul" },
                { value: "Rancaasih",           label: "Rancaasih" },
                { value: "Rancamulya",          label: "Rancamulya" }
            ], 
            "Cibogo" : [
                { value: "Sumurbarang",         label: "Sumurbarang" },
                { value: "Cibogo",              label: "Cibogo" },
                { value: "Cinangsi",            label: "Cinangsi" },
                { value: "Padaasih",            label: "Padaasih" },
                { value: "Sadawarna",           label: "Sadawarna" },
                { value: "Majasari",            label: "Majasari" },
                { value: "Cisaga",              label: "Cisaga" },
                { value: "Belendung",           label: "Belendung" },
                { value: "Cibalandong Jaya",    label: "Cibalandong Jaya" }
            ],
            "Cipunagara" : [
                { value: "Wanasari",            label: "Wanasari" },
                { value: "Sidamulya",           label: "Sidamulya" },
                { value: "Tanjung",             label: "Tanjung" },
                { value: "Jati",                label: "Jati" },
                { value: "Simpar",              label: "Simpar" },
                { value: "Parigimulya",         label: "Parigimulya" },
                { value: "Sidajaya",            label: "Sidajaya" },
                { value: "Padamulya",           label: "Padamulya" },
                { value: "Kosambi",             label: "Kosambi" },
                { value: "Manyingsal",          label: "Manyingsal" }
            ], 
            "Cijambe" : [
                { value: "Gunungtua",           label: "Gunungtua" },
                { value: "Cijambe",             label: "Cijambe" },
                { value: "Cirangkong",          label: "Cirangkong" },
                { value: "Cimenteng",           label: "Cimenteng" },
                { value: "Bantarsari",          label: "Bantarsari" },
                { value: "Tanjungwangi",        label: "Tanjungwangi" },
                { value: "Cikadu",              label: "Cikadu" },
                { value: "Sukahurip",           label: "Sukahurip" },
            ], 
            "Cipeundeuy" : [
                { value: "Karyamukti",          label: "Karyamukti" },
                { value: "Cimayasari",          label: "Cimayasari" },
                { value: "Lengkong",            label: "Lengkong" },
                { value: "Cipeundeuy",          label: "Cipeundeuy" },
                { value: "Wantilan",            label: "Wantilan" },
                { value: "Kosar",               label: "Kosar" },
                { value: "Sawangan",            label: "Sawangan" },
                { value: "Sukadana",            label: "Sukadana" }
            ], 
            "Legonkulon" : [
                { value: "Tegalurung",          label: "Tegalurung" },
                { value: "Bobos",               label: "Bobos" },
                { value: "Legonkulon",          label: "Legonkulon" },
                { value: "Mayangan",            label: "Mayangan" },
                { value: "Legonwetan",          label: "Legonwetan" },
                { value: "Karangmulya",         label: "Karangmulya" },
                { value: "Pangarengan",         label: "Pangarengan" }
            ], 
            "Cikaum" : [
                { value: "Kawunganten",         label: "Kawunganten" },
                { value: "Sindangsari",         label: "Sindangsari" },
                { value: "Cikaum Barat",        label: "Cikaum Barat" },
                { value: "Cikaum Timur",        label: "Cikaum Timur" },
                { value: "Mekarsari",           label: "Mekarsari" },
                { value: "Tanjungsari Barat",   label: "Tanjungsari Barat" },
                { value: "Tanjungsari Timur",   label: "Tanjungsari Timur" },
                { value: "Gandasari",           label: "Gandasari" },
                { value: "Pasirmuncang",        label: "Pasirmuncang" }
            ], 
            "Serangpanjang" : [
                { value: "Cijengkol",           label: "Cijengkol" },
                { value: "Talagasari",          label: "Talagasari" },
                { value: "Cintamekar",          label: "Cintamekar" },
                { value: "Ponggang",            label: "Ponggang" },
                { value: "Cikujang",            label: "Cikujang" },
                { value: "Cipancar",            label: "Cipancar" }
            ], 
            "Sukasari" : [
                { value: "Anggasari",           label: "Anggasari" },
                { value: "Batangsari",          label: "Batangsari" },
                { value: "Sukamaju",            label: "Sukamaju" },
                { value: "Mandalawangi",        label: "Mandalawangi" },
                { value: "Curugreja",           label: "Curugreja" },
                { value: "Sukasari",            label: "Sukasari" },
                { value: "Sukareja",            label: "Sukareja" }
            ],             
            "Tambakdahan" : [
                { value: "Tanjungrasa",         label: "Tanjungrasa" },
                { value: "Wanajaya",            label: "Wanajaya" },
                { value: "Gardumukti",          label: "Gardumukti" },
                { value: "Mariuk",              label: "Mariuk" },
                { value: "Kertajaya",           label: "Kertajaya" },
                { value: "Tambakdahan",         label: "Tambakdahan" },
                { value: "Rancaudik",           label: "Rancaudik" },
                { value: "Bojongkeding",        label: "Bojongkeding" },
                { value: "Bojonegara",          label: "Bojonegara" }
            ], 
            "Kasomalang" : [
                { value: "Sindangsari",         label: "Sindangsari" },
                { value: "Pasanggrahan",        label: "Pasanggrahan" },
                { value: "Bojongloa",           label: "Bojongloa" },
                { value: "Sukamelang",          label: "Sukamelang" },
                { value: "Tenjolaya",           label: "Tenjolaya" },
                { value: "Kasomalang Wetan",    label: "Kasomalang Wetan" },
                { value: "Kasomalang Kulon",    label: "Kasomalang Kulon" },
                { value: "Cimanglid",           label: "Cimanglid" }
            ], 
            "Dawuan" : [
                { value: "Manyeti",             label: "Manyeti" },
                { value: "Rawalele",            label: "Rawalele" },
                { value: "Sukasari",            label: "Sukasari" },
                { value: "Situsari",            label: "Situsari" },
                { value: "Dawuan Kaler",        label: "Dawuan Kaler" },
                { value: "Dawuan Kidul",        label: "Dawuan Kidul" },
                { value: "Cisampih",            label: "Cisampih" },
                { value: "Margasari",           label: "Margasari" },
                { value: "Batusari",            label: "Batusari" },
                { value: "Jambelaer",           label: "Jambelaer" }
            ], 
            "Pagaden Barat" : [
                { value: "Mekarwangi",          label: "Mekarwangi" },
                { value: "Bendungan",           label: "Bendungan" },
                { value: "Munjul",              label: "Munjul" },
                { value: "Margahayu",           label: "Margahayu" },
                { value: "Pangsor",             label: "Pangsor" },
                { value: "Sumurgintung",        label: "Sumurgintung" },
                { value: "Balimbing",           label: "Balimbing" },
                { value: "Cidahu",              label: "Cidahu" },
                { value: "Cidadap",             label: "Cidadap" }
            ], 
            "Ciater" : [
                { value: "Cisaat",              label: "Cisaat" },
                { value: "Ciater",              label: "Ciater" },
                { value: "Nagrak",              label: "Nagrak" },
                { value: "Cibeusi",             label: "Cibeusi" },
                { value: "Cibitung",            label: "Cibitung" },
                { value: "Palasari",            label: "Palasari" },
                { value: "Sanca",               label: "Sanca" }
            ], 
            "Pusakajaya" : [
                { value: "Kebondanas",          label: "Kebondanas" },
                { value: "Karanganyar",         label: "Karanganyar" },
                { value: "Pusakajaya",          label: "Pusakajaya" },
                { value: "Bojongtengah",        label: "Bojongtengah" },
                { value: "Bojongjaya",          label: "Bojongjaya" },
                { value: "Cigugur",             label: "Cigugur" },
                { value: "Cigugur Kaler",       label: "Cigugur Kaler" },
                { value: "Rangdu",              label: "Rangdu" }
            ], 
        };

        const sortOptions = (options) => {
            return options.sort((a, b) => a.label.localeCompare(b.label));
        };
        const handleKecamatanChange = (selectedOption) => {
            setFormData({ ...formData, kecamatan: selectedOption.value });
            setDesaOptions(sortOptions(desaData[selectedOption.value]));
        };
        const handleDesaChange = (selectedOption) => {
            setFormData({ ...formData, desa: selectedOption.value });
        };


        const __rules = async () => {
            const errors = {};
            if (!subCategoryValue) { errors.sub_kategori = 'Pilih salah satu kategori'; }
            if (!formData.nama) { errors.nama = 'Nama tidak boleh kosong'; }
            if (!formData.address) { errors.address = 'Alamat tidak boleh kosong'; }
            if (!formData.desa) { errors.desa = 'Desa tidak boleh kosong'; }
            if (!formData.kecamatan) { errors.kecamatan = 'Kecamatan tidak boleh kosong'; }
            if (!formData.title) { errors.title = 'Judul kegiatan tidak boleh kosong'; }
            if (!formData.background) { errors.background = 'Latar belakang tidak boleh kosong'; }
            if (!formData.total_fund) { errors.total_fund = 'Jumlah pengajuan dana harus diisi'; }
            if (!formData.jenis_anggaran) { errors.jenis_anggaran = 'Pilih salah satu jenis anggaran'; }
            if (!formData.desc) { errors.desc = 'Deskripsi harus diisi'; }
          
            return errors;
        };

        
        const handleHibahForm = async ( e ) => {
            e.preventDefault();
            setLoading(true)

            const errors = __rules();
            if (Object.keys(errors).length !== 0) {
                setErrorData(errors);
            } else {
                setErrorData({
                    sub_kategori: '',
                    nama: '',
                    address: '',
                    desa: '',
                    kecamatan: '',
                    title: '',
                    background: '',
                    total_fund: '',
                    jenis_anggaran: '',
                    desc: '',
                });
            }

            const dataAttach = Object.entries(attachmentData).map(([key, value]) => [key, value.file])

            imageData.map((item, index) => {
                if(!item.file){
                    Swal.fire({
                        text: 'Harap lengkapi gambar kegiatan anda',
                        icon: 'error'
                    })
                }
            })

            if( !proposalPengajuan.file || (requirementAttachement.length !== dataAttach.length)){
                Swal.fire({
                    text: 'Harap lengkapi dokumen anda',
                    icon: 'error'
                })
            }
            else{
                const sendData = {
                    jenis: hibahChecked ? 'Hibah' : bansosChecked && 'Bantuan Sosial (Bansos)',
                    sub_kategori: JSON.parse(subCategoryValue).id,
                    name: formData.nama,
                    npsn: NPSN === '' ? null: NPSN,
                    address: formData.address,
                    desa: formData.desa,
                    kecamatan: formData.kecamatan,
                    title: formData.title,
                    background: formData.background,
                    tujuan: goalList,
                    total_fund: formData.total_fund,
                    jenis_anggaran: formData.jenis_anggaran,
                    desc: formData.desc,
                    images: imageData.map((item) => item.file),
                    ...Object.fromEntries(Object.entries(attachmentData).map(([key, value]) => [key, value.file])),
                    proposal_file: proposalPengajuan.file,
                    dokumen_pendukung: dokumenPendukung.map((item) => item.file && item.file),
                }
                
                try {
                    const response = await ProposalStore('store', sendData);
                    if (response.status === 200 && response.data.new_proposal_info) {
                        Swal.fire({
                            html: response.data.message,
                            icon: 'success'
                        }).then(() => {
                            document.location.href = `/riwayat-proposal/detail/${response.data.new_proposal_info.slug}`;
                        });
                    }
                } catch (e) {
                    console.error('error: ', e);
                    Swal.fire({
                        text: 'An error occurred while submitting the form.',
                        icon: 'error'
                    });
                }
            }
            
            
            setLoading(false)
        }

    return(
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:mb-[5rem] md:mt-[-5rem] mb-4 mt-[-10px] container mx-auto md:px-[5rem] px-[1rem] z-[2] relative">
                <div className="border-gray-300 border-2 rounded-lg shadow-xl bg-white">
                    <div className="py-4 md:px-10 px-4">
                        <h1 className="text-center font-semibold md:text-[2rem] mb-3 text-[1.2rem]">Pendaftaran Hibah Bansos</h1>
                        <div>
                            <form action="" method="post" onSubmit={handleHibahForm}>
                                <div className="mb-4">
                                    <label className="md:text-[1rem] text-[.8rem]">Pilih Jenis Bantuan Yang dibutuhkan *</label>
                                    <br />
                                    <div className="flex justify-center mt-3">
                                        <label htmlFor="hibah-type" className={`font-semibold text-center w-[50%] border-primary border-[1px] rounded-l-lg py-2 ${hibahChecked ? 'bg-primary text-white' : 'text-primary'} md:text-[1rem] text-[.8rem]`}>
                                            <input
                                                required
                                                type="radio" 
                                                id="hibah-type" 
                                                name="type" 
                                                className="hidden"
                                                checked={hibahChecked}
                                                onChange={() => {
                                                    setLoading(true)
                                                    setHibahChecked(true);
                                                    setBansosChecked(false);
                                                    setSubCategoryValue("")
                                                }}
                                            />
                                            {hibahChecked ? (
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                            ) : (
                                                <FontAwesomeIcon icon={faCircle} className="me-2" />
                                            )}
                                            Hibah
                                        </label>
                                        <label htmlFor="bansos-type" className={`font-semibold text-center w-[50%] border-primary border-[1px] rounded-r-lg py-2 ${bansosChecked ? 'bg-primary text-white' : 'text-primary'} md:text-[1rem] text-[.8rem]`}>
                                            <input
                                                required
                                                type="radio" 
                                                id="bansos-type" 
                                                name="type" 
                                                className="hidden"
                                                checked={bansosChecked}
                                                onChange={() => {
                                                    setLoading(true)
                                                    setBansosChecked(true);
                                                    setHibahChecked(false);
                                                    setSubCategoryValue("")
                                                }}
                                            />
                                            {bansosChecked ? (
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                            ) : (
                                                <FontAwesomeIcon icon={faCircle} className="me-2" />
                                            )}
                                            Bantuan Sosial (Bansos)
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="sub_kategori" className="md:text-[1rem] text-[.9rem]">Kategori Bantuan *</label>
                                    <select 
                                        required
                                        value={subCategoryValue} 
                                        onChange={changeSubCategory}
                                        name="sub_kategori" 
                                        id="sub_kategori" 
                                        className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-1"
                                    >
                                        <option value="" disabled >Pilih Kategori</option>
                                        { subCategory && subCategory.map((item, index) => (
                                            <option value={JSON.stringify(item)} key={index}>{item.name}</option>
                                        )) }

                                    </select>
                                    { errorData && errorData.sub_kategori && errorData.sub_kategori !== '' && (
                                        <small className="text-red-500"><em>{errorData.sub_kategori}</em></small>
                                    )}
                                </div>

                                <div className="my-4">
                                    <h2 className="font-semibold md:text-[1.2rem]">Data Pengajuan</h2>
                                </div>

                                <div className="mb-4">
                                    <label 
                                        htmlFor="organisation-name" 
                                        className="md:text-[1rem] text-[.9rem]">
                                            Nama (Individu atau Organisasi) *
                                    </label>
                                    <input 
                                        required
                                        value={formData.nama} 
                                        onChange={(e) => setFormData({ ...formData, nama: e.target.value })} 
                                        className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                        id="organisation-name" 
                                        type="text" 
                                        placeholder="Ketik Disini" />
                                    { errorData && errorData.nama && errorData.nama !== '' && (
                                        <small className="text-red-500"><em>{errorData.nama}</em></small>
                                    )}
                                </div>
                                { requiredInputs && requiredInputs.map((item, index) => item.name === 'npsn' && (
                                    <div className="mb-4" key={index}>
                                        <label htmlFor={item.name} className="md:text-[1rem] text-[.9rem] uppercase">{item.name} *</label>
                                        <input 
                                            required 
                                            value={NPSN} 
                                            onChange={(e) => setNPSN(e.target.value )} 
                                            className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                            id={item.name} 
                                            type={
                                                item.name === 'npsn' ? 'number' : 'text'
                                            } 
                                            placeholder="Ketik Disini" />
                                    </div>
                                )) }
                                
                                <div className="md:grid md:grid-cols-3 md:gap-3">
                                    <div className="mb-4">
                                        <label htmlFor="Kabupaten" className="md:text-[1rem] text-[.9rem]">Kabupaten</label>
                                        <input 
                                            className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                            type="text" placeholder="Subang" disabled />
                                    </div>
                                    
                                    <div className="mb-4">
                                        <label htmlFor="kecamatan" className="md:text-[1rem] text-[.9rem]">Kecamatan *</label>
                                        <Select
                                            className="mt-2"
                                            required 
                                            value={kecamatanOptions.find(option => option.value === formData.kecamatan)}
                                            onChange={handleKecamatanChange}
                                            options={sortOptions(kecamatanData)}
                                            placeholder="Pilih kecamatan"
                                            isSearchable={true}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="desa" className="md:text-[1rem] text-[.9rem]">Desa *</label>
                                        <Select
                                            className="mt-2"
                                            required 
                                            value={desaOptions.find(option => option.value === formData.desa)}
                                            onChange={handleDesaChange}
                                            options={desaOptions}
                                            placeholder="Pilih desa"
                                            isSearchable={true}
                                        />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="address" className="md:text-[1rem] text-[.9rem]">Alamat Lengkap *</label>
                                    <textarea 
                                        required 
                                        onChange={(e) => setFormData({ ...formData, address: e.target.value })} 
                                        className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                        id="address" 
                                        rows="4" 
                                        placeholder="Masukan nama kampung/dusun/jalan, RT/RW, dan Nomor Rumah (jika ada)" 
                                        value={formData.address}></textarea>
                                    { errorData && errorData.address && errorData.address !== '' && (
                                        <small className="text-red-500"><em>{errorData.address}</em></small>
                                    )}
                                </div>


                                <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 mb-4">
                                    {imageData.map((attach, index) => (   
                                        <div className="md:mb-0 mb-3 md:mx-0 mx-3" key={index}>
                                            <div className="flex">
                                                <label className="md:text-[1rem] text-[.9rem]">Gambar Kegiatan *</label>
                                                { index > 0 && <FontAwesomeIcon icon={faTrash} className="text-red-500 ms-auto cursor-pointer" onClick={() => removeImage(index)} /> }
                                            </div>
                                            <label htmlFor={`imagedata-${index}`} className="md:text-[1rem] text-[.9rem]">
                                                <div className="w-full min-h-[150px] border-dashed border-red-500 border-2 rounded-lg flex items-center justify-center mt-1">
                                                    <div className="text-center">
                                                        <FontAwesomeIcon icon={faUpload} className="bg-gray-200 rounded-lg p-2" /><br />
                                                        { !attach.name ? (
                                                            <>
                                                                <span className="text-gray-400">Pilih File</span><br />
                                                                <span>jpg and png</span>
                                                            </>
                                                        ) : (
                                                            <span className="text-gray-400">{attach.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                            
                                            <input 
                                                type="file" 
                                                name="image[]" 
                                                id={`imagedata-${index}`}
                                                className="hidden"
                                                onChange={(e) => handleChangeImageData(e, index)}
                                                accept="image/jpg, image/png"
                                            />
                                        </div>                                                                              
                                    ))}                                    
                                    <label htmlFor="addImage" className="md:text-[1rem] text-[.9rem]">
                                        <div className="w-full min-h-[150px] border-dashed border-gray-300 border-2 rounded-lg flex items-center justify-center mt-7">
                                            <div className="text-center">                                                
                                                <button type="button" onClick={addImage}><FontAwesomeIcon icon={faPlus} className="bg-primary text-white rounded-lg p-2" /><br />Tambah Gambar </button>
                                            </div>
                                        </div>
                                    </label> 
                                </div>
                                
                                <div className="mb-4">
                                    <label htmlFor="title" className="md:text-[1rem] text-[.9rem]">Judul Kegiatan *</label>
                                    <input 
                                        required 
                                        value={formData.title} 
                                        onChange={(e) => setFormData({ ...formData, title: e.target.value })} 
                                        className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                        id="title" 
                                        type="text" 
                                        placeholder="Ex : Kegiatan bansos untuk desa ....." />
                                    { errorData && errorData.title && errorData.title !== '' && (
                                        <small className="text-red-500"><em>{errorData.title}</em></small>
                                    )}
                                </div>

                                
                                <div className="mb-4">
                                    <label htmlFor="background" className="md:text-[1rem] text-[.9rem]">Latar Belakang Kegiatan *</label>
                                    <textarea 
                                        required 
                                        onChange={(e) => setFormData({ ...formData, background: e.target.value })} 
                                        className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                        id="background" 
                                        rows="4" 
                                        placeholder="Tulis lengkap disini" 
                                        value={formData.background}></textarea>
                                    { errorData && errorData.background && errorData.background !== '' && (
                                        <small className="text-red-500"><em>{errorData.background}</em></small>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label>Maksud dan Tujuan *</label><br /> 
                                    {goalList.map((tujuan, index) => (   
                                        <div className="mb-3" key={index}>
                                            <div className="flex aling-center">
                                                <label htmlFor={`goal-${index}`} className="md:text-[1rem] text-[.9rem]">Tujuan {index + 1} *</label>
                                                { index > 0 && <div className="ms-auto"><FontAwesomeIcon onClick={() => removeGoals(index)} icon={faTrash} className="text-red-500 cursor-pointer" /></div>}
                                            </div>
                                            <input 
                                                value={tujuan} 
                                                onChange={(e) => { 
                                                    const newgoalList = [...goalList];
                                                    newgoalList[index] = e.target.value;
                                                    setGoalList(newgoalList);
                                                }}
                                                className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                                id={`goal-${index}`} 
                                                type="text" 
                                                placeholder="Tulis Disini" 
                                                required
                                            />
                                        </div>
                                    ))}
                                    <button type="button" onClick={addGoals}>Tambah Tujuan <FontAwesomeIcon icon={faPlusCircle} className="text-primary ms-2" /></button>
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-3">
                                    <div className="mb-4">
                                        <label htmlFor="total_fund" className="md:text-[1rem] text-[.9rem]">Jumlah Pengajuan Dana *</label>
                                        <input 
                                            required 
                                            value={formData.total_fund} 
                                            onChange={handleChange}
                                            className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-1" 
                                            id="total_fund" 
                                            type="text" 
                                            placeholder="Tuliskan disini" 
                                        />
                                        {errorMessage && <small className="text-red-500"><em>{errorMessage}</em></small>}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="budget-type" className="md:text-[1rem] text-[.9rem]">Jenis Anggaran *</label>
                                        <select 
                                            required 
                                            value={formData.jenis_anggaran} 
                                            onChange={(e) => setFormData({ ...formData, jenis_anggaran: e.target.value })} 
                                            name="budget_type" 
                                            id="budget-type" 
                                            className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-1">

                                            <option value="" disabled >Pilih jenis anggaran</option>
                                            { jenisAnggaran && jenisAnggaran.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )) }

                                        </select>
                                        { errorData && errorData.jenis_anggaran && errorData.jenis_anggaran !== '' && (
                                            <small className="text-red-500"><em>{errorData.jenis_anggaran}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="desc" className="md:text-[1rem] text-[.9rem]">Deskripsi *</label>
                                    <textarea 
                                        required 
                                        onChange={(e) => setFormData({ ...formData, desc: e.target.value })} 
                                        className="italic border rounded-lg w-full py-2 px-3 border-gray-300 text-gray-700 leading-tight focus:outline-none mt-2" 
                                        id="desc" 
                                        rows="4" 
                                        placeholder="Tuliskan disini" 
                                        value={formData.desc}></textarea>
                                    { errorData && errorData.desc && errorData.desc !== '' && (
                                        <small className="text-red-500"><em>{errorData.desc}</em></small>
                                    )}
                                </div>

                                <div className="my-4">
                                    <h2 className="font-semibold md:text-[1.2rem]">Data Dokumen</h2>
                                </div>

                                <div className="md:grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 mb-4">
                                    <div className="md:mb-0 mb-3 md:mx-0 mx-3">
                                        <label className="md:text-[1rem] text-[.9rem]">Proposal Pengajuan *</label>
                                        <label htmlFor="proposal-file" className="md:text-[1rem] text-[.9rem]">
                                            <div className="w-full min-h-[150px] border-dashed border-2 rounded-lg border-red-500 flex items-center justify-center mt-1">
                                                <div className="text-center">
                                                    <FontAwesomeIcon icon={faUpload} className="bg-gray-200 rounded-lg p-2" /><br />
                                                    { !proposalPengajuan.file ? (
                                                        <>
                                                            <span className="text-gray-400">Pilih File</span><br />
                                                            <span>format file pdf</span>
                                                        </>
                                                    ) : (
                                                        <span className="text-gray-400">{proposalPengajuan.name}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </label>
                                        <input 
                                            type="file" 
                                            name="proposal-file" 
                                            id="proposal-file" 
                                            className="hidden"
                                            onChange={(e) => handleChangeProposal(e)}
                                            accept="application/pdf"
                                        />
                                    </div>
                                    { requirementAttachement && requirementAttachement.map((item, index) => (
                                        <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                                            <label className="md:text-[1rem] text-[.9rem]">{item.name} *</label>
                                            <label htmlFor={item.name_input} className="md:text-[1rem] text-[.9rem]">
                                                <div className="w-full min-h-[150px] border-dashed border-2 rounded-lg border-red-500 flex items-center justify-center mt-1">
                                                    <div className="text-center">
                                                        <FontAwesomeIcon icon={faUpload} className="bg-gray-200 rounded-lg p-2" /><br />
                                                        { !attachmentData[item.name_input] ? (
                                                            <>
                                                                <span className="text-gray-400">Pilih File</span><br />
                                                                <span>pdf, jpg, and png</span>
                                                            </>
                                                        ) : (
                                                            <span className="text-gray-400">{attachmentData[item.name_input].name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                            <input
                                                type="file" 
                                                name={item.name_input} 
                                                id={item.name_input} 
                                                className="hidden"
                                                onChange={(e) => handleChangeImage(e, item.name_input, index)}
                                                accept="image/jpg, image/png, application/pdf"
                                            />
                                        </div>
                                    )) }
                                    {dokumenPendukung.map((attach, index) => (   
                                        <div className="md:mb-0 mb-3 md:mx-0 mx-3" key={index}>
                                            <div className="flex">
                                                <label className="md:text-[1rem] text-[.9rem]">Dokumen Pendukung</label>
                                                { index > 0 && <FontAwesomeIcon icon={faTrash} className="text-red-500 ms-auto cursor-pointer" onClick={() => removeDokumen(index)} /> }
                                            </div>
                                            <label htmlFor={`dokumen-pendukung-${index}`} className="md:text-[1rem] text-[.9rem]">
                                                <div className="w-full min-h-[150px] border-dashed border-gray-500 border-2 rounded-lg flex items-center justify-center mt-1">
                                                    <div className="text-center">
                                                        <FontAwesomeIcon icon={faUpload} className="bg-gray-200 rounded-lg p-2" /><br />
                                                        { !attach.name ? (
                                                            <>
                                                                <span className="text-gray-400">Pilih File</span><br />
                                                                <span>jpg, png or pdf</span>
                                                            </>
                                                        ) : (
                                                            <span className="text-gray-400">{attach.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                            <input 
                                                type="file" 
                                                name="dokumen_pendukung[]" 
                                                id={`dokumen-pendukung-${index}`}
                                                className="hidden"
                                                onChange={(e) => handleChangeDokumenPendukung(e, index)}
                                                accept="image/jpg, image/png, application/pdf"
                                            />
                                        </div>
                                    ))}
                                    
                                    <label htmlFor="addImage" className="md:text-[1rem] text-[.9rem]">
                                        <div className="w-full min-h-[150px] border-dashed border-gray-300 border-2 rounded-lg flex items-center justify-center mt-7">
                                            <div className="text-center">                                                
                                                <button type="button" onClick={addDokumen}><FontAwesomeIcon icon={faPlus} className="bg-primary text-white rounded-lg p-2" /><br />Tambah Dokumen </button>
                                            </div>
                                        </div>
                                    </label> 
                                </div>
                                <div className="mb-3">
                                    <button className="w-full bg-primary text-white p-2 rounded-lg">Daftar</button>
                                </div>
                                                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HibahForm;