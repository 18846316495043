/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Logo from '../../assets/images/logo.png';
import NavbarBeforeLogin from './NavbarBeforeLogin';
import NavbarAfterLogin from './NavbarAfterLogin';
import Cookies from 'js-cookie';

const NavbarComponent = () => {
    const [ isLogin, setIsLogin ] = useState(false);
    const [ openResponsive, setOpenResponsive ] = useState(false);
    const checkLogin = Cookies.get('login');

    useEffect(() => {
        if(checkLogin){
            setIsLogin(true);
        } else{
            setIsLogin(false)
        }
    },[checkLogin])
    return(
        <>
            <nav className="bg-white border-gray-200 border-b-gray-200 border-2 shadow-lg">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={Logo} className="h-12" alt="SI ABAH JAWARA" />
                    </a>
                    <button onClick={() => { setOpenResponsive(!openResponsive) }} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-none focus:outline-none focus:ring-2 focus:ring-gray-300" aria-controls="navbar-default" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                        </svg>
                    </button>
                    { !isLogin ? (
                        <div className={`${openResponsive ? 'show' : 'hidden'} w-full lg:block lg:w-auto`} id="navbar-default">
                            <NavbarBeforeLogin />
                        </div>
                    ) : (
                        <div className={`${openResponsive ? 'show' : 'hidden'} w-full lg:block lg:w-auto`} id="navbar-default">
                            <NavbarAfterLogin />
                        </div>
                    )}
                </div>
            </nav>
        </>
    )
}
export default NavbarComponent;