import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import RegisterForm from "../../components/register/RegisterForm";

function RegisterView () {
    return(
        <>
            <NavbarComponent />
            <RegisterForm />
            <CopyrightComponent />
        </>
    )
}
export default RegisterView;