/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import LogoutProcess from '../LogoutProcess';
import Cookies from 'js-cookie';

const NavbarAfterLogin = () => {
    const [dropdownProfile, setDropdownProfile] = useState(false);
    const logoutProcess = () => {
        LogoutProcess();
    }

    const [profileForm, setProfileForm] = useState({
        username: '',
 
    });

    useEffect(() => {
        const getData = () => {
            const user = JSON.parse(Cookies.get('user_info'));
            setProfileForm({
                username: user.username,
            });
        };
        getData();
    }, []);
    
    return (
        <ul className="font-medium flex flex-col p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 dark:border-gray-700">
            <li>
                <a href="/tentang" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-3 md-py-2 hover:opacity-[50%] duration-500">Tentang</a>
            </li>
            <li>
                <a href="/riwayat-proposal" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-3 md-py-2 hover:opacity-[50%] duration-500">Riwayat Pengajuan</a>
            </li>
            <li>
                <a href="/list-proposal" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-3 md-py-2 hover:opacity-[50%] duration-500">Proposal Hibah</a>
            </li>
            <li>
                <a href="/pendaftaran-hibah-bansos" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-3 md-py-2 hover:opacity-[50%] duration-500">Mendaftar Hibah Bansos</a>
            </li>
            <li>
                <a href="/informasi" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-3 md-py-2 hover:opacity-[50%] duration-500">Informasi</a>
            </li>
            <li className='md:mt-0 mt-3'>
            <a href="#" onClick={() => { setDropdownProfile(!dropdownProfile) }} 
                className="block py-2 px-3 text-white md:border-b-0 border-b-[1px] border-gray-500 rounded bg-primary md:px-3 md:py-2 hover:opacity-[50%] duration-500"><FontAwesomeIcon className="me-2" icon={faUserCircle} /> {profileForm.username}</a>
                <div className={`${dropdownProfile ? 'absolute' : 'hidden'} md:mt-2 mt-2 bg-white md:ms-1 left-[50%] translate-x-[-50%] md:left-[auto] md:translate-x-[auto] z-[1] lg:w-[auto] w-[90%]`}>
                    <ul className='w-[100%]'>
                        <li className="rounded-t border border-gray-800 w-[100%] py-2 px-3">
                            <a href="/profile"><FontAwesomeIcon icon={faUser} className='me-2' /> Lihat Profil</a>
                        </li>
                        <li className="rounded-b border border-gray-800 w-[100%] py-2 px-3">
                            <a href="#" onClick={logoutProcess}><FontAwesomeIcon icon={faRightToBracket} className='me-2' /> Keluar</a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    )
}
export default NavbarAfterLogin;