import axios from "axios";
import Cookies from "js-cookie";

const Login = async ( data ) => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.post(`${api}/login`, data);
        return req;
    } catch (error) {
        return error.response;
    }
}
const Register = async ( data ) => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.post(`${api}/register`, data);
        return req;
    } catch (error) {
        return error.response;
    }
}

const LupaPassword = async ( data ) => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.post(`${api}/forgot-password`, data);
        return req;
    } catch (error) {
        return error.response;
    }
}

const ResetPassword = async( data ) => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.post(`${api}/reset-password`, data);
        return req;
    } catch (error) {
        return error.response;
    }
}

const Logout = async () => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.post(`${api}/logout`, {}, {
            headers: {
                Authorization: `Bearer ${Cookies.get('__token')}`
            }
        });
        return req;
    } catch (error) {
        return error.response;
    }
}

const Auth = async( method, data ) => {
    if( method === 'login' ) {
        return Login(data)
    } else if ( method === 'register' ) {
        return Register(data)
    } else if ( method === 'lupa-password' ) {
        return LupaPassword(data)
    } else if ( method === 'reset-password' ) {
        return ResetPassword(data)
    } else if ( method === 'logout' ) {
        return Logout()
    }
}

export default Auth;