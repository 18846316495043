import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import ProposalDetailCover from "../../components/proposal-detail/ProposalDetailCover";

function ProposalDetailView () {
    return(
        <>
            <NavbarComponent />
            <ProposalDetailCover />
            <CopyrightComponent />
        </>
    )
}
export default ProposalDetailView;