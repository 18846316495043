/* eslint-disable jsx-a11y/anchor-is-valid */
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Proposal from "../../service/Proposal";

const DocumentDetail = () => {
    const { id } = useParams()
    const assets = process.env.REACT_APP_ASSETS_KEY;
    const [ data, setData ] = useState({})
    useEffect(() => {
        const getDetailProposal = async (id) => {
            try {
                const response = await Proposal('detail', id);
                setData(response.data.data)
            } catch (e) { console.error('error : ', e);}
        }
        getDetailProposal(id)
    }, [id])
    return(
        <>
            <div className="lg:grid lg:grid-cols-3 lg:gap-5 mb-4">
                <div className="md:mb-0 mb-3 md:mx-0 mx-3">
                    <label className="md:text-[.9rem] text-[.8rem]">Proposal Pengajuan</label>
                    <label className="md:text-[.9rem] text-[.8rem]">
                        <div className="w-full min-h-[125px] border-dashed border-2 rounded-lg border-gray-200 flex items-center justify-center mt-1">
                            <a href={data.proposal_file_url} target="__blank" className="text-center">
                                <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                <span className="text-gray-400">Lihat File</span><br />
                                <span className="whitespace-normal break-all">{data.proposal_file}</span>
                            </a>
                        </div>
                    </label>
                </div>
                {data.dokumen_wajib && data.dokumen_wajib.length > 0 && data.dokumen_wajib.map((item, index) => (
                    <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                        <label className="md:text-[.9rem] text-[.8rem]">{item.name}</label>
                        <label htmlFor="proposal" className="md:text-[.9rem] text-[.8rem]">
                            <div className="w-full min-h-[125px] border-dashed border-2 rounded-lg border-gray-200 flex items-center justify-center mt-1">
                                <a href={`${assets}/file-uploads/${item.pivot.content}`} target="__blank" className="text-center">
                                    <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                    <span className="text-gray-400">Lihat File</span><br />
                                    <span className="whitespace-normal break-all">{item.pivot.content}</span>
                                </a>
                            </div>
                        </label>
                    </div>
                ))}
                {data.dokumen_pendukung && data.dokumen_pendukung.length > 0 && data.dokumen_pendukung.map((item, index) => (
                    <div key={index} className="md:mb-0 mb-3 md:mx-0 mx-3">
                        <label className="md:text-[.9rem] text-[.8rem]">Dokumen Pendukung</label>
                        <label htmlFor="accountPhotocopy" className="md:text-[.9rem] text-[.8rem]">
                            <div className="w-full min-h-[125px] border-dashed border-2 rounded-lg border-gray-200 flex items-center justify-center mt-1">
                                <a href={item.dokumen_file_url} target="__blank" className="text-center">
                                    <FontAwesomeIcon icon={faEye} className="bg-gray-200 rounded-lg p-2" /><br />
                                    <span className="text-gray-400">Lihat File</span><br />
                                    <span className="whitespace-normal break-all">{item.dokumen_file}</span>
                                </a>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
            {data.status === 'pending by user' ? (
                <button 
                    onClick={() => document.location.href=`/riwayat-proposal/edit/${data.slug}`}
                    className="bg-yellow-300 w-full p-2 rounded-xl">
                
                    Edit Data

                </button>
            ) : ('')}
        </>
    )
}
export default DocumentDetail;