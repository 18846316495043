import CopyrightComponent from "../../components/CopyrightComponent";
import HibahForm from "../../components/hibah-registration/HibahForm";
import TopHibahComponent from "../../components/hibah-registration/TopHibahComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";

function HibahRegistrationView(){
    return(
        <>
            <NavbarComponent />
            <TopHibahComponent />
            <HibahForm />
            <CopyrightComponent />
        </>
    )
}
export default HibahRegistrationView;