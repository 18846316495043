import axios from "axios";
import Cookies from "js-cookie";

const GantiPassword = async (data) => {
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.post(`${api}/update-password`, data, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return req;
    } catch (error) {
        return error;
    }
}

const GantiProfile = async (data) => {
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.post(`${api}/update-profile`, data, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return req;
    } catch (error) {
        return error;
    }
}
const Profile = async(method, data) => {
    if( method === 'password' ) {
        return GantiPassword(data)
    } else if( method === 'profile' ) {
        return GantiProfile(data)
    }
}
export default Profile;