import { useEffect, useState } from "react";
import CopyrightComponent from "../../components/CopyrightComponent";
import BannerComponent from "../../components/homepage/BannerComponent";
import DataComponent from "../../components/homepage/DataComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import Cookies from "js-cookie";
import HomepageAfterLogin from "../../components/homepage/HomepageAfterLogin";


function HomepageView(){
    const [ isLogin, setIsLogin ] = useState(false);
    const checkLogin = Cookies.get('login');

    useEffect(() => {
        if(checkLogin){
            setIsLogin(true);
        } else{
            setIsLogin(false)
        }
    },[checkLogin])

    return(
        <div>
            <NavbarComponent />
            { !isLogin ? (
                <div>
                    <BannerComponent />
                    <DataComponent />
                </div>
            ) : (
                <HomepageAfterLogin className="min-h-[100vh]" />
            )}
            <CopyrightComponent />            
        </div>
    )
}
export default HomepageView;