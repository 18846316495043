const ProposalAll = ( query ) => {
    const dataQuery = query.data.data;
    return (
        <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg responsive">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 border shadow-xl">
                    <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                        <tr>
                            <th scope="col" className="px-3 py-3">
                                No
                            </th>
                            <th scope="col" className="px-6 py-3">
                                No. Permohonan
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Judul Proposal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nama Pengaju
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Tanggal Pengajuan
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataQuery && dataQuery.map((item, index) => (
                            <tr key={index} className="bg-white border-b text-black text-[.8rem]">
                                <th className="px-3 py-4">
                                    {index + 1}
                                </th>
                                <th className="px-6 py-4">
                                    {item.no_permohonan}
                                </th>
                                <td className="px-6 py-4">
                                    {item.title}
                                </td>
                                <td className="px-6 py-4">
                                    {item.name}
                                </td>
                                <td className="px-6 py-4">
                                    {item.tgl_pengajuan}
                                </td>
                                <td className="px-6 py-4">
                                    {item.status}
                                </td>
                                <td className="px-6 py-4">
                                <a href={`/list-proposal/detail/${item.slug}`} className="bg-primary text-white rounded-lg px-3 py-2">Selengkapnya</a>
                                </td>
                            </tr>
                        ))}
                        {dataQuery && dataQuery.length === 0 && (
                            <tr className="bg-white border-b text-black text-[.8rem]">
                                <th colSpan={7} scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center">
                                    <em>Tidak ada data proposal</em>
                                </th>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default ProposalAll;