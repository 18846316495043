import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import ProposalCover from "../../components/proposal-riwayat/ProposalCover";

function ProposalRiwayatView(){
    return(
        <>
            <NavbarComponent />
            <ProposalCover />
            <CopyrightComponent />
        </>
    )
}
export default ProposalRiwayatView;