import Cookies from "js-cookie";
import Auth from "../service/Auth";
import Swal from "sweetalert2";

const LogoutProcess = async () => {
    try {
        const response = await Auth('logout');
        if( response.status === 200 ){
            Swal.fire({
                html: response.data.message,
                icon: "success"
            }).then(() => {
                Cookies.remove('login');
                Cookies.remove('__token');
                Cookies.remove('user_info');
                window.location.href = '/';
            })
            return 0;
        }
    } catch (e) {
        console.error('error : ', e);
    }
}
export default LogoutProcess;