import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowCircleDown, faArrowCircleUp, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Proposal from '../../service/Proposal';
import { useParams } from 'react-router';
import LoaderComponent from '../LoaderComponent';

const TimelineData = () => {
    const { id } = useParams()
    const [ data, setData ] = useState({})
    const [ loading, setLoading ] = useState(true)
    const [ statusOpen, setStatusOpen ] = useState(false)
    const [ timelineData, setTimelineData ] = useState([]);
    useEffect(() => {
        const getDetailProposal = async (id) => {
            try {
                const response = await Proposal('list-detail', id);
                setData(response.data.data)
                setTimelineData(response.data.data.proposal_log)
                setLoading(false)
            } catch (e) { console.error('error : ', e); setLoading(false) }
        }
        getDetailProposal(id)
    }, [id])

    const options = { 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric',
        hour12: false
    };
    return(
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:grid md:grid-cols-3 md:gap-5 mb-5">
                <div className='col-span-3'>
                    <strong className='md:text-[1.5rem] text-[1.1rem]'>
                        Status Pemrosesan
                    </strong>
                </div>
                <div className="md:mb-1 mb-3 col-span-3">
                    <button onClick={() => setStatusOpen(!statusOpen)} className='md:w-1/2 w-full flex border border-gray-300 p-3 rounded-lg items-center md:text-[1.05rem] text-[.8rem]'>
                        <span className='me-auto'>Nomer Permohonan: <strong>{data.no_permohonan}</strong></span>
                        <FontAwesomeIcon className='ms-auto' icon={statusOpen ? faArrowCircleDown : faArrowCircleUp} />
                    </button>
                </div>
                <div className={`mb-3 col-span-3 ${statusOpen ? `block` : `hidden`}`}>
                    <ul className="items-center flex flex-shrink-0 overflow-x-auto no-scrollbar p-5">
                        { timelineData.slice().reverse().map((item, index) => (
                            <li key={index} className={`relative mb-0 flex-shrink-0 ${timelineData.length > 1 && 'lg:w-1/2' } w-[100%]`}>
                                <div className="flex items-center">
                                    <div 
                                        className={`
                                        ${
                                            (item.desc).includes('Dikembalikan') ?
                                            `bg-yellow-500 ring-yellow-300` :
                                            ((item.desc).includes('Ditolak') ?
                                            `bg-red-500 ring-red-300` :
                                            `bg-primary ring-green-200`)
                                        }
                                        z-10 flex items-center justify-center w-6 h-6 rounded-full text-white ring-8 shrink-0`}>
                                        
                                        {
                                            (item.desc).includes('Dikembalikan') ?
                                                (<FontAwesomeIcon icon={faInfoCircle}  />  )
                                            : (
                                                (item.desc).includes('Ditolak') ?
                                                (<FontAwesomeIcon icon={faTimes}  />  ) :
                                                (<FontAwesomeIcon icon={faCheck}  />  )
                                            )
                                        }
                                    </div>
                                    <div className={`w-full ${index === (timelineData.length - 1) && `hidden`} 
                                        ${
                                            (item.desc).includes('Dikembalikan') ?
                                            `bg-yellow-500` :
                                            ((item.desc).includes('Ditolak') ?
                                            `bg-red-500` :
                                            `bg-primary`)
                                        }
                                         h-0.5`}></div>
                                </div>
                                <div className="mt-3 pe-8">
                                    <h2 className='text-justify leading-[18px] lg:text-base text-[.8rem] mb-1'>{item.desc}</h2>
                                    <p className="leading-[18px] lg:text-base text-[.8rem] font-normal text-gray-500 dark:text-gray-400 mb-2">{item.ket}</p>
                                    <small className='text-[.7rem] text-gray-500'>{new Date(item.created_at).toLocaleDateString('id-ID', options)}</small>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default TimelineData;