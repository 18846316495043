/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import LoaderComponent from "../LoaderComponent";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Auth from "../../service/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
const NavbarBeforeLogin = () => {
    const [ loading, setLoading ] = useState(false);

    const [ formLogin, setFormLogin ] = useState({
        username: '',
        password: '',
        check: false
    })
    const [ formLoginError, setFormLoginError ] = useState({
        username: '',
        password: ''
    })
    
    const __rules = () => {
        const errors = {};
        if (!formLogin.username) {
            errors.username = 'Username tidak boleh kosong';
        }
        if (!formLogin.password) {
            errors.password = 'Kata sandi tidak boleh kosong';
        }
        return errors;
    }

    const submitFormLogin = async ( e ) => {
        e.preventDefault();
        setLoading(true);
        const errors = __rules();
        if (Object.keys(errors).length === 0) {
            const data = {
                username: formLogin.username,
                kata_sandi: formLogin.password
            };
            try {
                const response = await Auth('login', data);
                if( response.status === 401 ) {
                    Swal.fire({
                        text: response.data.message,
                        icon: 'error'
                    })
                } else if ( response.status === 200 ) {
                    Cookies.set('login', true, { expires: 1 / 3 });
                    Cookies.set('__token', response.data.data.token, { expires: 1 / 3 })
                    Cookies.set('user_info', JSON.stringify(response.data.data.user_info), { expires: 1 / 3 })
                    
                    if(formLogin.check){
                        const savebox = {
                            username: formLogin.username,
                            password: formLogin.password,
                        };
                        Cookies.set('checkbox', JSON.stringify(savebox));
                    }

                    Swal.fire({
                        text: response.data.message,
                        icon: 'success'
                    }).then(() => {
                        document.location.href = '/';
                    })
                }
            } catch ( e ) { console.error('errror : ', e); }
            setLoading(false)
        } else {
            setFormLoginError(errors);
            setLoading(false);
        }
    }

    const [ dataForgot, setDataForgot ] = useState({email: ''});
    const submitForgot = async ( e ) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await Auth('lupa-password', dataForgot);
            if( response.data.error ) {
                Swal.fire({
                    html: response.data.error[0],
                    icon: "error"
                })
            } else {
                Swal.fire({
                    html: response.data.message,
                    icon: "success"
                }).then(() => {
                    document.location.href='/reset-password'
                })
            }
            setLoading(false)
        } catch (e) { console.error('error : ', e); }
    }

    const checkbox = Cookies.get('checkbox');
    useState(() => {
        if(checkbox){
            const parsedData = JSON.parse(checkbox);
            setFormLogin({
                username: parsedData.username,
                password: parsedData.password,
                check: true,
            })
        }
    }, [checkbox])

    const [ openLoginModal, setOpenLoginModal ] = useState(false);
    const [ openLoginModalAnimation, setOpenLoginModalAnimation ] = useState(true);
    const ruleOpenLoginModal = (status) => {
        if(status === 'open') {
            setOpenLoginModalAnimation(true)
            setOpenLoginModal(true)
        } else {
            setOpenLoginModalAnimation(false)
            setTimeout(() => {
                setOpenLoginModal(false)
            }, 500);
        }
    }

    const [ openForgotModal, setOpenForgotModal ] = useState(false);
    const [ openForgotModalAnimation, setOpenForgotModalAnimation ] = useState(true);
    const ruleOpenForgotModal = (status) => {
        if(status === 'open') {
            setOpenLoginModalAnimation(false)
            setOpenLoginModal(false)
            setOpenForgotModalAnimation(true)
            setOpenForgotModal(true)
        } else {
            setOpenForgotModalAnimation(false)
            setTimeout(() => {
                setOpenForgotModal(false)
            }, 500);
        }
    }

    const [ openPassword, setOpenPassword ] = useState(false)
    return(
        <div>
            { loading && (
                <LoaderComponent />
            )}
            <ul className="font-medium flex flex-col p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 dark:border-gray-700 ">
                <li>
                    <a href="/tentang" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-0 md-py-2 hover:opacity-[50%] duration-500">Tentang</a>
                </li>
                <li>
                    <a href="/list-proposal" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-0 md-py-2 hover:opacity-[50%] duration-500">Proposal Hibah</a>
                </li>
                <li>
                    <a href="/informasi" className="block py-2 px-3 text-secondary md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-secondary md:px-0 md-py-2 hover:opacity-[50%] duration-500">Informasi</a>
                </li>
                <li>
                    <a onClick={() => ruleOpenLoginModal('open')} href="#" className="block py-2 px-3 text-blue-800 md:border-b-0 border-b-[1px] border-gray-500 rounded md:bg-transparent md:text-blue-800 md:px-0 md-py-2 hover:opacity-[50%] duration-500">Login</a>
                </li>
            </ul>
            <div id="login-modal" tabIndex="-1" aria-hidden="true" className={`${!openLoginModal ? 'hidden' : 'block'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
                <div className="relative p-4 w-full h-[90vh] max-h-full flex justify-center items-center">
                    <div className={`bg-white rounded-lg shadow border border-2 border-gray-200 text-center md:w-[50%] w-full  animate__animated ${openLoginModalAnimation ? `animate__bounceIn` : `animate__bounceOut`}`}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h1 className="text-center font-bold md:text-[1.5rem] text-[1.2rem]">Login</h1>
                            <button type="button" onClick={() => ruleOpenLoginModal('close')} className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="login-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="px-4 md:px-5 py-2">
                            <form method="post" onSubmit={submitFormLogin} className="text-start">
                                <div className="md:mt-4 mt-3">
                                    <input value={formLogin.username} onChange={(e) => setFormLogin({...formLogin, username: e.target.value})} type="text" id="username" className={`bg-gray-50 italic border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${formLoginError.username ? 'border-red-500' : 'border-gray-300'} `} placeholder="Username" />
                                    {formLoginError.username !== '' && (
                                        <small className="text-red-500"><em>{formLoginError.username}</em></small>
                                    )}
                                </div>
                                <div className="md:mt-4 mt-3 relative">
                                    <input value={formLogin.password} onChange={(e) => setFormLogin({...formLogin, password: e.target.value})} type={openPassword ? 'text' : 'password'} id="password" className={`bg-gray-50 italic border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 ps-2.5 pe-9 ${formLoginError.password ? 'border-red-500' : 'border-gray-300'}`} placeholder="Kata Sandi" />
                                    <FontAwesomeIcon icon={!openPassword ? faEye : faEyeSlash} onClick={() => setOpenPassword(!openPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                    {formLoginError.password !== '' && (
                                        <small className="text-red-500"><em>{formLoginError.password}</em></small>
                                    )}
                                </div>
                                <div className="md:mt-4 mt-3">
                                    <label htmlFor="check" className="flex items-center">
                                        <input checked={formLogin.check} id="check" onChange={(e) => setFormLogin({...formLogin, check: !formLogin.check})} type="checkbox" /> <small className="mb-[1px] ms-2">Ingat kata sandi</small>
                                    </label>
                                </div>
                                <div className="md:mt-4 mt-3">
                                    <button className="w-full bg-primary text-white p-2 rounded-xl">Masuk</button>
                                </div>
                                <div className="md:mt-4 mt-3 text-end font-semibold">
                                    <button type="button" onClick={() => ruleOpenForgotModal('open')} className="text-red-500 text-sm">Lupa Password ?</button>
                                </div>
                                <div className="md:mt-4 mt-3 text-center font-semibold">
                                    Belum Punya Akun? <a href="/daftar" className="text-primary">Daftar</a>
                                </div>
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="lupa-password" tabIndex="-1" aria-hidden="true" className={`${!openForgotModal ? 'hidden' : 'block'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
                <div className="relative p-4 w-full h-[90vh] max-h-full flex justify-center items-center">
                    <div className={`bg-white rounded-lg shadow border border-2 border-gray-200 text-center md:w-[50%] w-full animate__animated ${openForgotModalAnimation ? `animate__bounceIn` : `animate__bounceOut`}`}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h1 className="text-center font-bold md:text-[1.5rem] text-[1.2rem]">Lupa Password</h1>
                            <button type="button" onClick={() => ruleOpenForgotModal('close')} className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="login-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="px-4 md:px-5 py-2">
                            <form method="post" onSubmit={submitForgot}>
                                <div className="md:mt-4 mt-3">
                                    <input 
                                        value={dataForgot.email} 
                                        onChange={(e) => setDataForgot({email: e.target.value})} 
                                        type="email" 
                                        id="forgot" 
                                        className={`bg-gray-50 italic border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 'border-gray-300 `} 
                                        placeholder="Masukkan email untuk reset password anda !" 
                                        required />
                                </div>
                                <div className="md:mt-4 mt-3">
                                    <button className="w-full bg-primary text-white p-2 rounded-xl">Kirim Token</button>
                                </div>
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NavbarBeforeLogin;