import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderComponent from '../LoaderComponent';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Profile from '../../service/Profile';

const ProfileComponent = () => {
    const [ loading, setLoading ] = useState(false);

    const [ profileForm, setProfileForm ] = useState({
        username: '',
        name: '',
        address: '',
        phoneNumber: '',
        nik: '',
        email: '',
        jenis_kelamin: '',
    })

    const [ profileFormError, setprofileFormError ] = useState({
        username: '',
        name: '',
        address: '',
        phoneNumber: '',
        nik: '',
        email: '',
        jenis_kelamin: '',
    })

    useEffect(() => {
        const getData = () => {
            const user = JSON.parse(Cookies.get('user_info'));
            setProfileForm({
                username: user.username,
                name: user.fullname,
                address: user.address,
                phoneNumber: user.phone,
                nik: user.nik,
                email: user.email,
                jenis_kelamin: user.gender,
            })
        }
        getData();
    }, [])

    const __rules = () => {
        const errors = {};
        if ( !profileForm.username ) { errors.username = 'Nama pengguna tidak boleh kosong'; }
        if ( !profileForm.name ) { errors.name = 'Nama lengkap tidak boleh kosong'; }
        if ( !profileForm.address ) { errors.address = 'Alamat tidak boleh kosong'; }
        if ( !profileForm.phoneNumber ) { errors.phoneNumber = 'No. Hp tidak boleh kosong'; }
        if ( !profileForm.jenis_kelamin ) { errors.jenis_kelamin = 'Jenis kelamin tidak boleh kosong'; }
        if ( !profileForm.nik ) { 
            errors.nik = 'NIK tidak boleh kosong'; 
        } else if ( !/^\d{16}$/.test(profileForm.nik) ) {
            errors.nik = 'NIK harus berisikan 16 angka'; 
        }
        if (!profileForm.email) {
            errors.email = 'Email tidak boleh kosong';
        } else if (!/\S+@\S+\.\S+/.test(profileForm.email)) {
            errors.email = 'Format email tidak valid';
        }
        return errors;
    }

    const handleEditProfile = async ( e ) => {
        e.preventDefault();
        setLoading(true);
        setprofileFormError({
            username: '',
            name: '',
            address: '',
            phoneNumber: '',
            nik: '',
            email: '',
            jenis_kelamin: '',
        })
        const errors = __rules();
        if (Object.keys(errors).length === 0) {
            const dataSend = {
                username: profileForm.username,
                nama_lengkap: profileForm.name,
                alamat: profileForm.address,
                nomor_hp: profileForm.phoneNumber,
                nik: profileForm.nik,
                jenis_kelamin: profileForm.jenis_kelamin,
                email: profileForm.email,
            }

            try {
                const response = await Profile('profile', dataSend);
                if(response.status === 200) {
                    if(response.data.error){
                        Swal.fire({
                            html: response.data.error[0],
                            icon: "error"
                        })
                    } else {
                        Swal.fire({
                            html: response.data.message,
                            icon: "success"
                        })
                    }
                }
                setLoading(false)
            } catch (e) { console.error('error : ', e); }
        } else {
            setprofileFormError(errors);
            setLoading(false);
        }
    };

    const [ openPassword, setOpenPassword ] = useState(false)
    const [ openConfirmPassword, setOpenConfirmPassword ] = useState(false)

    const [ openPasswordModal, setOpenPasswordModal ] = useState(false);
    const [ openPasswordAnimation, setOpenPasswordAnimation ] = useState(true);
    const ruleOpenPasswordModal = (status) => {
        if(status === 'open') {
            setOpenPasswordAnimation(true)
            setOpenPasswordModal(true)
        } else {
            setOpenPasswordAnimation(false)
            setTimeout(() => {
                setOpenPasswordModal(false)
            }, 500);
        }
    }

    const [ formPassword, setFormPassword ] = useState({
        kata_sandi: '',
        kata_sandi_confirmation: ''
    })
    const [ formPasswordError, setFormPasswordError ] = useState({
        kata_sandi: '',
        kata_sandi_confirmation: ''
    })

    const __passwordRules = () => {
        const errors = {};
        if ( !formPassword.kata_sandi ) { 
            errors.kata_sandi = 'Kata sandi tidak boleh kosong';
        } else if (formPassword.kata_sandi.length < 8) {
            errors.kata_sandi = 'Kata sandi minimal 8 karakter';
        }
        if ( !formPassword.kata_sandi_confirmation ) { 
            errors.kata_sandi_confirmation = 'Konfirmasi kata sandi tidak boleh kosong';
        } else if ( formPassword.kata_sandi !== formPassword.kata_sandi_confirmation ){ 
            errors.kata_sandi_confirmation = 'Konfirmasi kata sandi tidak sama'; 
        }
        return errors;
    }

    const handleEditPassword = async ( e ) => {
        e.preventDefault();
        setLoading(true);
        setFormPasswordError({
            kata_sandi: '',
            kata_sandi_confirmation: ''
        })
        const errors = __passwordRules();
        if (Object.keys(errors).length === 0) {
            setLoading(false)
            try {
                const response = await Profile('password', formPassword);
                if(response.status === 200) {
                    Swal.fire({
                        text: response.data.message,
                        icon: 'success'
                    }).then(() => {
                        ruleOpenPasswordModal('close');
                        setLoading(false);
                        setFormPassword({
                            kata_sandi: '',
                            kata_sandi_confirmation: ''
                        })
                    })
                }
            } catch (e) { console.error('error : ', e); }
        } else {
            setFormPasswordError(errors);
            setLoading(false);
        }
    };

    return(
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:my-[5rem] my-4 container mx-auto md:px-[5rem] px-[1rem]">
                <div className="border-gray-300 border-2 rounded-lg shadow-xl">
                    <div className="text-center py-4 md:px-10 px-4">
                        <h1 className="font-semibold md:text-[2rem] mb-3 text-[1.2rem]">Profil Saya</h1>
                        <br />
                        <div>
                            <form action="" method="post" onSubmit={handleEditProfile}>
                                <div className="mb-3 text-start">
                                    <input className={`italic border rounded-lg w-full py-2 px-3 ${profileFormError.username ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} id="username" value={profileForm.username} onChange={ (e) => setProfileForm({...profileForm, username: e.target.value}) } type="text" placeholder="Nama Pengguna" />
                                    {profileFormError.username !== '' && (
                                        <small className="text-red-500"><em>{profileFormError.username}</em></small>
                                    )}
                                </div>
                                <div className="mb-3 text-start">
                                    <input className={`italic border rounded-lg w-full py-2 px-3 ${profileFormError.name ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={profileForm.name} onChange={ (e) => setProfileForm({...profileForm, name: e.target.value}) } id="name" type="text" placeholder="Nama Lengkap (Individu atau Lembaga)" />
                                    {profileFormError.name !== '' && (
                                        <small className="text-red-500"><em>{profileFormError.name}</em></small>
                                    )}
                                </div>
                                <div className="mb-3 text-start">
                                    <textarea className={`italic border rounded-lg w-full py-2 px-3 mb-[-5px] ${profileFormError.address ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={profileForm.address} onChange={ (e) => setProfileForm({...profileForm, address: e.target.value}) } id="address" rows="4" placeholder="Alamat Lengkap" ></textarea>
                                    {profileFormError.address !== '' && (
                                        <small className="text-red-500"><em>{profileFormError.address}</em></small>
                                    )}
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-3">
                                <div className="mb-3 text-start">
                                        <select 
                                            className={`italic border rounded-lg w-full py-2 px-3 ${profileFormError.jenis_kelamin ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                            value={profileForm.jenis_kelamin} 
                                            onChange={ (e) => setProfileForm({...profileForm, jenis_kelamin: e.target.value}) } 
                                            id="jenis_kelamin">
                                            <option value="" disabled>-- Pilih Jenis Kelamin --</option>
                                            <option value="Laki - Laki">Laki - Laki</option>
                                            <option value="Perempuan">Perempuan</option>
                                        </select>
                                        {profileFormError.nik !== '' && (
                                            <small className="text-red-500"><em>{profileFormError.jenis_kelamin}</em></small>
                                        )}
                                    </div>
                                    <div className="mb-3 text-start">
                                        <input className={`italic border rounded-lg w-full py-2 px-3 ${profileFormError.phoneNumber ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={profileForm.phoneNumber} onChange={ (e) => setProfileForm({...profileForm, phoneNumber: e.target.value}) } id="phone-number" type="number" placeholder="No. Hp Pendaftar" />
                                        {profileFormError.phoneNumber !== '' && (
                                            <small className="text-red-500"><em>{profileFormError.phoneNumber}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className="md:grid md:grid-cols-2 md:gap-3">
                                    <div className="mb-3 text-start">
                                        <input className={`italic border rounded-lg w-full py-2 px-3 ${profileFormError.nik ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} value={profileForm.nik} onChange={ (e) => setProfileForm({...profileForm, nik: e.target.value}) } id="nik" type="number" placeholder="NIK Pendaftar" />
                                        {profileFormError.nik !== '' && (
                                            <small className="text-red-500"><em>{profileFormError.nik}</em></small>
                                        )}
                                    </div>
                                    <div className="mb-3 text-start">
                                        <input className={`italic border rounded-lg w-full py-2 px-3 ${profileFormError.email ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} id="email" type="text" placeholder="Email aktif pendaftar"  value={profileForm.email} onChange={ (e) => setProfileForm({...profileForm, email: e.target.value}) } />
                                        {profileFormError.email !== '' && (
                                            <small className="text-red-500"><em>{profileFormError.email}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className='md:grid md:grid-cols-2 md:gap-3'>
                                    <div className="mb-3">
                                        <button type='button' onClick={() => ruleOpenPasswordModal('open')} className="w-full bg-blue-500 text-white p-2 rounded-lg">Edit Password</button>
                                    </div>
                                    <div className="mb-3">
                                        <button type='submit' className="w-full bg-primary text-white p-2 rounded-lg">Simpan</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="lupa-password" tabIndex="-1" aria-hidden="true" className={`${!openPasswordModal ? 'hidden' : 'block'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full`}>
                <div className="relative p-4 w-full h-[90vh] max-h-full flex justify-center items-center">
                    <div className={`bg-white rounded-lg shadow border border-2 border-gray-200 text-center md:w-[50%] w-full animate__animated ${openPasswordAnimation ? `animate__bounceIn` : `animate__bounceOut`}`}>
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h1 className="text-center font-bold md:text-[1.5rem] text-[1.2rem]">Ganti Password</h1>
                            <button type="button" onClick={() => ruleOpenPasswordModal('close')} className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="login-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="px-4 md:px-5 py-2">
                            <form method="post" onSubmit={handleEditPassword}>
                                <div className="md:mt-4 mt-3">
                                    <div className="mb-3 text-start relative">
                                        <input 
                                            className={`italic border rounded-lg w-full py-2 ps-3 pe-9 ${formPasswordError.kata_sandi ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                            id="password" 
                                            value={formPassword.kata_sandi} 
                                            onChange={ (e) => setFormPassword({...formPassword, kata_sandi: e.target.value}) } 
                                            type={openPassword ? 'text' : 'password'}
                                            placeholder="Atur Kata Sandi" />
                                        <FontAwesomeIcon icon={!openPassword ? faEye : faEyeSlash} onClick={() => setOpenPassword(!openPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                        {formPasswordError.kata_sandi !== '' && (
                                            <small className="text-red-500"><em>{formPasswordError.kata_sandi}</em></small>
                                        )}
                                    </div>
                                    <div className="mb-3 text-start relative">
                                        <input 
                                            className={`italic border rounded-lg w-full py-2 ps-3 pe-9 ${formPasswordError.kata_sandi_confirmation ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                            id="password-confirmation" 
                                            value={formPassword.kata_sandi_confirmation} 
                                            onChange={ (e) => setFormPassword({...formPassword, kata_sandi_confirmation: e.target.value}) } 
                                            type={openConfirmPassword ? 'text' : 'password'}
                                            placeholder="Konfirmasi Kata Sandi" />
                                        <FontAwesomeIcon icon={!openConfirmPassword ? faEye : faEyeSlash} onClick={() => setOpenConfirmPassword(!openConfirmPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                        {formPasswordError.kata_sandi_confirmation !== '' && (
                                            <small className="text-red-500"><em>{formPasswordError.kata_sandi_confirmation}</em></small>
                                        )}
                                    </div>
                                </div>
                                <div className="md:mt-4 mt-3">
                                    <button className="w-full bg-primary text-white p-2 rounded-xl">Simpan</button>
                                </div>
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProfileComponent;