import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import HibahForm from "../../components/proposal-edit/HibahForm";
import TopHibahComponent from "../../components/proposal-edit/TopHibahComponent";

function ProposalRiwayatEdit(){
    return(
        <>
            <NavbarComponent />
            <TopHibahComponent />
            <HibahForm />
            <CopyrightComponent />
        </>
    )
}
export default ProposalRiwayatEdit;