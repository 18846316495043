import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "animate.css";
import './App.scss';

import HibahRegistrationView from './pages/hibah-registration/HibahRegistrationView';
import IsLogin from './middleware/IsLogin';
import ProposalRiwayatView from './pages/proposal-riwayat/ProposalRiwayatView';
import ProposalRiwayatEdit from './pages/proposal-riwayat/ProposalRiwayatEdit';
import ProposalDetailView from './pages/proposal-detail/ProposalDetailView';
import ProfileView from './pages/profile/ProfileView';

import HomepageView from './pages/homepage/HomepageView';
import RegisterView from './pages/auth/RegisterView';
import TentangView from './pages/tentang/TentangView';
import InformasiView from './pages/informasi/InformasiView';
import ResetView from './pages/reset-password/ResetView';
import ProposalListView from './pages/proposal-list/ProposalListView';
import ProposalListDetail from './pages/proposal-list/ProposalListDetail';
import VerifikasiEmail from './pages/auth/VerifikasiEmail';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomepageView />} />
        <Route path='/daftar' element={<RegisterView />} />
        <Route path='/verifikasi/:token/:status' element={<VerifikasiEmail />} />
        <Route path='/tentang' element={<TentangView />} />
        <Route path='/informasi' element={<InformasiView />} />
        <Route path='/reset-password' element={<ResetView />} />
        <Route path='/list-proposal' element={<ProposalListView />} />
        <Route path='/list-proposal/detail/:id' element={<ProposalListDetail />} />

        <Route
          path='/pendaftaran-hibah-bansos'
          element={
            <IsLogin >
              <HibahRegistrationView />
            </IsLogin>
            }
        />
        <Route
          path='/riwayat-proposal'
          element={
            <IsLogin >
              <ProposalRiwayatView />
            </IsLogin>
            }
        />
        <Route
          path='/riwayat-proposal/detail/:id'
          element={
            <IsLogin >
              <ProposalDetailView />
            </IsLogin>
            }
        />
        <Route
          path='/riwayat-proposal/edit/:id'
          element={
            <IsLogin >
              <ProposalRiwayatEdit />
            </IsLogin>
            }
        />
        <Route
          path='/profile'
          element={
            <IsLogin >
              <ProfileView />
            </IsLogin>
            }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
