import DocumentDetail from "./DocumentDetail";
import TimelineData from "./TimelineData";
import TopDetail from "./TopDetail";

const ProposalDetailCover = () => {
    return(
        <>
            <div className="md:my-[5rem] my-4 container mx-auto lg:px-[7.5rem] px-[1rem]">
                <div className="border-gray-300 border-2 rounded-lg shadow-xl md:p-5 p-3">
                    <div className="md:px-[5rem] md:pb-[2rem] md:pt-[2rem] pb-[2rem] px-3 text-black">
                        <TopDetail />
                        <TimelineData />
                        <DocumentDetail />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProposalDetailCover;