import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const SearchProposal = () => {
    const [ search, setSearch ] = useState('');
    const submitFormSearch = (e) => {
        e.preventDefault();
        document.location.href = `?search=${search}`;
    }
    return (
        <>
            <form className="relative flex flex-nowrap items-stretch" onSubmit={submitFormSearch}>
                <input
                    required
                    type="text"
                    className="p-3 relative m-0 block w-[1px] min-w-0 flex-auto rounded-l-lg border border-solid border-gray-400 font-normal text-gray-500 outline-none focus:outline-none italic"
                    placeholder="Cari Proposal"
                    aria-label="search"
                    aria-describedby="addon-wrapping"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <button
                    className="p-3 flex items-center rounded-r-lg bg-primary border border-l-0 border-solid text-center border-neutral-600 text-white md:text-[1.3rem]"
                    id="addon-wrapping"
                >
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </form>
        </>
    )
}
export default SearchProposal;