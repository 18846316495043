import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";

import Verified from '../../assets/images/verified.gif';

function VerifikasiEmail () {
    return(
        <>
            <NavbarComponent />
            <div className="absolute w-full h-screen flex items-center justify-center top-0 left-0 px-5 z-[-1]">
                <div className="border-gray-300 border-2 rounded-lg shadow-xl pt-5 pb-10 px-3">
                    <div className="md:flex items-center">
                        <img src={Verified} alt="verification-success" className="md:w-[200px] w-[50%] md:ms-0 ms-[25%]" />
                        <div className="md:text-left text-center md:mt-0 mt-5">
                            <h3 className="font-bold md:text-[2rem]">Verifikasi Akun Berhasil</h3>
                            <p>
                                Sekarang anda dapat login menggunakan akun yang telah anda daftarkan sebelumnya
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 w-full">
                <CopyrightComponent />
            </div>
        </>
    )
}
export default VerifikasiEmail;