import React from 'react';
import { Link } from 'react-router-dom';

import Error from '../assets/images/error.gif';

class NotFound extends React.Component {
    render(){
        return (
            <div className="bg-[#aff0e4] flex h-screen w-full items-center justify-center">
                <div className='w-full text-center md:mt-[-100px] mt-[-50px]'>
                    <img src={Error} className='lg:w-[50%] md:w-[64%] w-[80%] lg:ms-[25%] md:ms-[18%] ms-[10%] text-center' alt='error' />
                    <h1 className="md:text-[2rem] text-[1.5rem] font-bold text-black md:mt-[-50px]">Page not found !</h1>
                    <p className="mb-7">Oops! Halaman yang Anda cari tidak ditemukan.</p>
                    <Link to="/" className="bg-primary text-white py-2 px-4 md:text-base text-[.8rem] rounded-xl">Kembali ke Menu Utama</Link>
                </div>
            </div>
        );
    }
}

export default NotFound;