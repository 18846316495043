import axios from "axios";
import Cookies from "js-cookie";

const ProposalSubCategory = async(value) => {
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.get(`${api}/sub_kategori/${value}`, {
            headers: {
                Authorization: `Bearer ${bearer}`
            }
        });
        return req;
    } catch (error) {
        return error.response;
    }
}

const storeData = async (data) => {
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.post(`${api}/proposal`, data, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return req;
    } catch (error) {
        return error;
    }
}

const updateData = async (value) => {
    const api = process.env.REACT_APP_API_KEY;
    const bearer = Cookies.get('__token');
    try{
        const req = await axios.post(`${api}/proposal-update/${value.id}`, value.data, {
            headers: {
                Authorization: `Bearer ${bearer}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return req;
    } catch (error) {
        return error;
    }
}


const ProposalStore = (query, data) => {
    if( query === 'sub-kategori' ) return ProposalSubCategory(data)
    else if ( query === 'store' ) return storeData(data)
    else if ( query === 'update' ) return updateData(data)
}
export default ProposalStore;