import CopyrightComponent from "../../components/CopyrightComponent";
import NavbarComponent from "../../components/navbar-components/NavbarComponent";
import ResetComponent from "../../components/reset-password/ResetComponent";

function ResetView () {
    return(
        <>
            <NavbarComponent />
            <ResetComponent />
            <CopyrightComponent />
        </>
    )
}
export default ResetView;