import Logo from '../../assets/images/logo.png';

const HomepageAfterLogin = () => {
    return(
        <div className="md:my-[5rem] my-10 container mx-auto lg:px-[10rem] md:px-[5rem] px-[1rem]">
            <div className="border-gray-300 border-2 rounded-lg shadow-xl lg:px-[7rem] md:px-[3rem] px-[2rem] md:py-[5rem] py-[2rem] text-center">
                <img src={Logo} alt="" className='w-[100%]' />
                <br />
                <p className='text-gray-700 md:text-[1rem] text-[.5rem] text-pretty pb-7'>Setelah anda terdaftar dan masuk sebagai masyarakat teregistrasi di Program Bansos & Hibah Online ini, anda dapat mengajukan proposal pengajuan dana sesusai SOP yang berlaku.</p>
                <br />
                <button onClick={() => document.location.href = '/pendaftaran-hibah-bansos'} className='border-primary border-2 md:w-[50%] w-[100%] md:text-[1rem] text-[.75rem] rounded-lg py-2 font-bold text-primary mb-3'>
                    Ajukan Proposal Hibah
                </button>
                <br />
                <button onClick={() => document.location.href = '/riwayat-proposal'} className='border-primary border-2 md:w-[50%] w-[100%] md:text-[1rem] text-[.75rem] rounded-lg py-2 font-bold text-white bg-primary'>
                    Daftar Proposal Pengajuan Saya
                </button>
            </div>
        </div>
    )
}
export default HomepageAfterLogin;