import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const IsLogin = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() => {
        const checkLoginStatus = () => {
            if (!Cookies.get('login')) {
              Swal.fire({
                title: 'Halaman tidak bisa diakses',
                text: 'Silahkan login terlebih dahulu!',
                icon: 'error',
              }).then(() => {
                navigate('/');
              });
            }
          };
      
          checkLoginStatus();
    }, [navigate])
    return <>{children}</>;
}
export default IsLogin;