import axios from "axios";

const LandingPageSettings = async() => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.get(`${api}/landingpage-settings`);
        return req;
    } catch (error) {
        return error.response;
    }
}
export default LandingPageSettings;