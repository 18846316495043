import React, { useState } from 'react';
import LoaderComponent from '../LoaderComponent';
import Swal from 'sweetalert2';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Auth from '../../service/Auth';

const ResetComponent = () => {
    const [ loading, setLoading ] = useState(false);
    const [ resetForm, setResetForm ] = useState({
        token: '',
        kata_sandi: '',
        kata_sandi_confirmation: '',
    })

    const [ resetFormError, setResetFormError ] = useState({
        token: '',
        kata_sandi: '',
        kata_sandi_confirmation: '',
    })

    const __rules = () => {
        const errors = {};
        if ( !resetForm.token ) { errors.token = 'Token tidak boleh kosong'; }
        if ( !resetForm.kata_sandi ) { 
            errors.kata_sandi = 'Kata sandi tidak boleh kosong'; 
        } else if (resetForm.kata_sandi.length < 8) {
            errors.kata_sandi = 'Kata sandi minimal 8 karakter'; 
        }
        if ( !resetForm.kata_sandi_confirmation ) { 
            errors.kata_sandi_confirmation = 'Konfirmasi kata sandi tidak boleh kosong';
        } else if ( resetForm.kata_sandi !== resetForm.kata_sandi_confirmation ){ 
            errors.kata_sandi_confirmation = 'Konfirmasi kata sandi tidak sama'; 
        }
        return errors;
    }

    const handleReset = async ( e ) => {
        e.preventDefault();
        setLoading(true);
        setResetFormError({
            token: '',
            kata_sandi: '',
            kata_sandi_confirmation: '',
        })
        const errors = __rules();
        if (Object.keys(errors).length === 0) {
            const response = await Auth('reset-password', resetForm);
            if( response.status === 401 ) {
                Swal.fire({
                    text: response.data.message,
                    icon: 'error'
                })
            } else if ( response.status === 200 ) {
                Swal.fire({
                    text: response.data.message,
                    icon: 'success'
                }).then(() => {
                    document.location.href = '/';
                })
            }
        } else {
            setResetFormError(errors);
        }
        setLoading(false);
    };

    const [ openPassword, setOpenPassword ] = useState(false)
    const [ openConfirmPassword, setOpenConfirmPassword ] = useState(false)

    return(
        <>
            { loading && (
                <LoaderComponent />
            )}
            <div className="md:my-[5rem] my-4 container mx-auto md:px-[5rem] px-[1rem]">
                <div className="border-gray-300 border-2 rounded-lg shadow-xl">
                    <div className="text-center py-4 md:px-10 px-4">
                        <h1 className="font-semibold md:text-[2rem] mb-3 text-[1.2rem]">Reset Password Anda </h1>
                        <br />
                        <div>
                            <form action="" method="post" onSubmit={handleReset}>
                                <div className="mb-3 text-start">
                                    <input 
                                        className={`italic border rounded-lg w-full py-2 px-3 ${resetFormError.token ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                        id="token" 
                                        value={resetForm.token} 
                                        onChange={ (e) => setResetForm({...resetForm, token: e.target.value}) } 
                                        type="text" 
                                        placeholder="Tulis token anda disini" />
                                    {resetFormError.token !== '' && (
                                        <small className="text-red-500"><em>{resetFormError.token}</em></small>
                                    )}
                                </div>
                                <div className="mb-3 text-start relative">
                                    <input 
                                        className={`italic border rounded-lg w-full py-2 ps-3 pe-9 ${resetFormError.kata_sandi ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                        id="kata_sandi" 
                                        value={resetForm.kata_sandi} 
                                        onChange={ (e) => setResetForm({...resetForm, kata_sandi: e.target.value}) } 
                                        type={openPassword ? 'text' : 'password'}
                                        placeholder="Atur kata sandi" />
                                    <FontAwesomeIcon icon={!openPassword ? faEye : faEyeSlash} onClick={() => setOpenPassword(!openPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                    {resetFormError.kata_sandi !== '' && (
                                        <small className="text-red-500"><em>{resetFormError.kata_sandi}</em></small>
                                    )}
                                </div>
                                <div className="mb-3 text-start relative">
                                    <input 
                                        className={`italic border rounded-lg w-full py-2 ps-3 pe-9 ${resetFormError.kata_sandi_confirmation ? `border-red-500` : `border-gray-300`} text-gray-700 leading-tight focus:outline-none`} 
                                        id="kata_sandi_confirmation" 
                                        value={resetForm.kata_sandi_confirmation} 
                                        onChange={ (e) => setResetForm({...resetForm, kata_sandi_confirmation: e.target.value}) } 
                                        type={openConfirmPassword ? 'text' : 'password'}
                                        placeholder="Konfirmasi kata sandi" />
                                    <FontAwesomeIcon icon={!openConfirmPassword ? faEye : faEyeSlash} onClick={() => setOpenConfirmPassword(!openConfirmPassword)} className="cursor-pointer absolute top-3 right-[15px]" />
                                    {resetFormError.kata_sandi_confirmation !== '' && (
                                        <small className="text-red-500"><em>{resetFormError.kata_sandi_confirmation}</em></small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <button className="w-full bg-primary text-white p-2 rounded-lg">Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetComponent;
