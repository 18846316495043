import axios from "axios";

const ProposalSummary = async() => {
    const api = process.env.REACT_APP_API_KEY;
    try{
        const req = await axios.get(`${api}/proposal-summary`);
        return req;
    } catch (error) {
        return error.response;
    }
}
export default ProposalSummary;